import {BrowserRouter , Routes ,Route ,NavLink,Outlet} from 'react-router-dom';
import '../../css/navlink.css';
import Card from 'react-bootstrap/Card';

export default function NavlinkCant(){

    return(
        
         <>

<Card>
      <Card.Header style={{display:'flex' , justifyContent:'center'}}>
      Suivi De Canitne
      </Card.Header>
      </Card>
<Outlet/>
         </>
    )
}