import {BrowserRouter , Routes ,Route ,NavLink,Outlet} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import '../../css/navlink.css';
export default function Navlinkf(){

    return(
        
         <>

    <Card>
      <Card.Header style={{display:'flex' , justifyContent:'center'}}>
      Fromulaires
      </Card.Header>
      </Card>

<Outlet/>
         </>
    )
}