import React, { useEffect, useState } from 'react';
import {BrowserRouter , Routes ,Route ,NavLink,Outlet,Link} from 'react-router-dom';
import '../../css/navlink.css';
import axios from 'axios';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInbox ,faShareFromSquare,faFloppyDisk,faTrash} from '@fortawesome/free-solid-svg-icons';
export default function navlink(){


    return(        
         <>
         <Card>
      <Card.Header style={{ justifyContent:'center'}} className='navlink'>
        <Nav variant="pills" defaultActiveKey="#first">
          <Nav.Item>
          <NavLink to="/Inbox/inbox" style={{textDecoration:'none'}}>
          <Nav.Link href="#first"> <FontAwesomeIcon icon={faInbox} style={{marginRight:'10px'}} />Message Recu</Nav.Link>
            </NavLink>
          </Nav.Item>
          <NavLink to="/Inbox/send" style={{textDecoration:'none'}}>
          <Nav.Link href="#send"><FontAwesomeIcon icon={faShareFromSquare} style={{marginRight:'10px'}} />Message envoyés</Nav.Link>
            </NavLink>
            <NavLink to="/Inbox/save" style={{textDecoration:'none'}}>
          <Nav.Item>
            <Nav.Link href="#link" > <FontAwesomeIcon icon={faFloppyDisk} style={{marginRight:'10px'}}/>Message enregistrés</Nav.Link>
          </Nav.Item>
          </NavLink>
          <Nav.Item>

          <NavLink to="/Inbox/trash" style={{textDecoration:'none'}}>
          <Nav.Item>
            <Nav.Link href="#linkks"><FontAwesomeIcon icon={faTrash}style={{marginRight:'10px'}} />Corbeille</Nav.Link>
          </Nav.Item>
          </NavLink>

          </Nav.Item>
        </Nav>
      </Card.Header>

      <Card.Header style={{ justifyContent:'center'}} className='navlinkres'>
        <Nav variant="pills" defaultActiveKey="#first" >
          <Nav.Item>
          <NavLink to="/Inbox/inbox" style={{textDecoration:'none'}}>
          <Nav.Link href="#first"> <FontAwesomeIcon icon={faInbox} /> </Nav.Link>
            </NavLink>
          </Nav.Item>
          <NavLink to="/Inbox/send" style={{textDecoration:'none'}}>
          <Nav.Link href="#send"><FontAwesomeIcon icon={faShareFromSquare} /> </Nav.Link>
            </NavLink>
            <NavLink to="/Inbox/save" style={{textDecoration:'none'}}>
          <Nav.Item>
            <Nav.Link href="#link" > <FontAwesomeIcon icon={faFloppyDisk} /> </Nav.Link>
          </Nav.Item>
          </NavLink>
          <Nav.Item>

          <NavLink to="/Inbox/trash" style={{textDecoration:'none'}}>
          <Nav.Item>
            <Nav.Link href="#linkks"><FontAwesomeIcon icon={faTrash} /></Nav.Link>
          </Nav.Item>
          </NavLink>

          </Nav.Item>
        </Nav>
      </Card.Header>
      </Card>

<Outlet/>

         </>
    )
}       