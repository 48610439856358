import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from "xlsx";

import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

function Note(props) {
  const server = props.server;
  const[excel,setExcel]=useState([]);
  const [data, setData] = useState([]);
  const [isDirty, setIsDirty] = useState(false); // État pour suivre si des modifications ont été apportées
  const [originalData, setOriginalData] = useState([]); // État pour stocker les données d'origine
  const [searchQuery, setSearchQuery] = useState(""); 
  const [infos,setInfos] =useState([]);
  const [eta,setEta] =useState([]);
  const [note,setNote]=useState([]);
  const [statut,setSat] =useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function launch_toast() {
    var x = document.getElementById("toast")
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2000);
}
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchDatanote();
  }, []);

  useEffect(() => {
  
    axios.get(`${server}/info_u`).then((res) => {

      setInfos(res.data);
      });
    }, []);
    
    useEffect(() => {
  
      axios.get(`${server}/note_down`).then((res) => {
        setExcel(res.data);
        
        });
  
   
      }, []);
    
      const fetchDatanote = async () => {
        try {
          axios.get(`${server}/statut`).then((res) => {
            const foundNote = res.data.find((sta) => sta.note === 0);
            if (foundNote) {
              setNote(1);
            } else {
              setNote(0);
            }
            setSat(res.data);
          })
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  

      const handleLock = () => {
        axios.post(`${server}/statut_note`, { note })
          .then(response => {
            console.log("Données mises à jour avec succès !");
            setOriginalData(data);
            fetchDatanote();
            launch_toast();
          })
          .catch(error => {
            console.error("Erreur lors de la mise à jour des données :", error);
          });
      };
    

    
      useEffect(() => {
    // À chaque changement de l'état data, on met à jour l'état isDirty en comparant avec les données d'origine
    const hasChanged = JSON.stringify(data) !== JSON.stringify(originalData);
    setIsDirty(hasChanged);
  }, [data, originalData]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${server}/note`);
      setData(response.data);
      setOriginalData(response.data); // Stocker les données d'origine lorsqu'elles sont chargées
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const filterData = (query) => {
    if (!query) {
      return data;
    }

    return data.filter(item => {
      const { VMAT, NOM, PRENOM } = item;
      const lowercaseQuery = query.toLowerCase();

      return (
        String(VMAT).includes(lowercaseQuery) ||
        NOM.toLowerCase().includes(lowercaseQuery) ||
        PRENOM.toLowerCase().includes(lowercaseQuery)
      );
    });
  };



  const handleInputChange = (e, VMAT, field) => {
    const newData = data.map(item => {
      if (item.VMAT === VMAT) {
        let value = parseInt(e.target.value, 10);
        if (isNaN(value)) {
          value = 0;
        }
  
        if (field === 'NOTE') {
          const maxNote = 40;
          value = Math.min(value, maxNote);
        }
        if (field === 'ABS') {
          const maxAbs = 90;
          value = Math.min(value, maxAbs); // Limit the value to the maximum allowed absence value
          // Send updated absence value to the server
          axios.post(`${server}/update_abs`, { VMAT, value })
            .then(response => {
              console.log("Updated absence value successfully!");
            })
            .catch(error => {
              console.error("Error updating absence value:", error);
            });
        }
  
        return { ...item, [field]: value };
      }
      return item;
    });
    setData(newData);
  };
  
  const handleSaveAll = () => {
    axios.post(`${server}/note_update`, data)
      .then(response => {
        console.log("Données mises à jour avec succès !");
        setOriginalData(data); // Mettre à jour les données d'origine après avoir sauvegardé les modifications
        launch_toast();  
    })
      .catch(error => {
        console.error("Erreur lors de la mise à jour des données :", error);
      });
  };
  useEffect(() => {
  
    axios.get(`${server}/user_pn`).then((res) => {
      setEta(res.data);
      });
    }, []);

    const handleUpdateClick = () => {
   
      axios.post(`${server}/updateRNote`)
          .then(response => {
              console.log(response.data);
          })
          .catch(error => {
              console.error(error);
          });
  
};

const handleReset = () => {
  axios.post(`${server}/Reset_note`)
    .then(response => {
      console.log("Données reset avec succès !");
      handleClose();
      launch_toast();
    })
    .catch(error => {
      console.error("Erreur lors de la mise à jour des données :", error);
    });
};
const togglePopup = () => {
  setShowPopup(!showPopup);
};

const closePopup = () => {
  setShowPopup(false);
};

  const exportToExcel = () => {
    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excel);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert the workbook to an Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a Blob object and create a temporary link to trigger the download
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;

    var today = new Date();
    // Formater la date selon vos préférences
    var formattedDate = today.toLocaleDateString('fr-FR'); // Vous pouvez ajuster le paramètre de localisation selon votre besoin
    // Créer le nom du fichier en ajoutant la date formatée
      a.download = 'Note_' + formattedDate + '.xlsx';
    document.body.appendChild(a);
    a.click();
    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const [filtreNom, setFiltreNom] = useState('');

  // Utilisez la fonction de filtre pour obtenir les éléments filtrés
  const itemsFiltres = eta.filter(item => {
    // Filtrer les noms en fonction de la valeur de l'input
    return (
      item.LIBETABA.toLowerCase().includes(filtreNom.toLowerCase()) ||
      item.LIBETAB.toLowerCase().includes(filtreNom.toLowerCase())
    );
  });


  return (
    <>

<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Attention</Modal.Title>
        </Modal.Header>
        <Modal.Body>êtes vous sûr de vouloir reinitialiser les notes de prime</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleReset}>
            Oui
          </Button>
        </Modal.Footer>
      </Modal>
        





      <Card style={{marginTop:'20px'}}>   
      <Card.Header>
       <div >
        <div id="toast"><div id="img"><FontAwesomeIcon icon={faCircleCheck} /></div><div id="desc">Modifier avec succes</div></div>
        <div className="">
       



        </div>

        {
  infos.map((info) => {
    return info.type === "PAIE" ? (


<>          
        <div style={{display:'flex' ,alignItems:'center'}}>
 
  <p style={{ fontSize: '20px', fontWeight: '500', marginRight: '20px',marginTop:'10px'}}>Note</p>

  {infos.length > 0 && infos[0].role === "Chef de Service" ? (
    <> 
<div style={{display:'flex',float:'right'}}> 
<Form style={{marginLeft:'20px',marginRight:'20px',width:'30%'}}>
          <Form.Group   controlId="exampleForm.ControlInput1">
            <Form.Control type="text" placeholder="Rechercher....."          value={filtreNom}
        onChange={(e) => setFiltreNom(e.target.value)}></Form.Control>
          </Form.Group>
    
        </Form> 
<Button  onClick={handleLock}>
       { statut.map((stat) => {
        return stat.note === 0 ? ( <>Bloquer</>) : (<>Débloquer</>) })}
        </Button>
        <Button style={{marginLeft:'10px'}} onClick={exportToExcel}>
      Télécharger
    </Button>
    <Button variant="secondary" style={{marginLeft:'10px'}} onClick={handleShow}>Réinitialiser</Button>
    <Button style={{marginLeft:'10px'}} onClick={handleUpdateClick}>
      Relancer
    </Button>
        </div>
        
    </>
  ):(<></>)}

</div>




      </>
    ) : (
      statut.map((stat) => {
        return stat.note === 0 ? (  
          <div style={{display:'flex'}}>     
          <h4 style={{marginLeft:'10px',marginTop:'10px'}}>Absences</h4>
          
             <Form style={{marginLeft:'20px',marginTop:'10px',width:'30%'}}>
          <Form.Group   controlId="exampleForm.ControlInput1">
            <Form.Control type="text" placeholder="Rechercher....."           value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}/>
          </Form.Group>
    
        </Form>       
          <div style={{width:'90%',marginTop:'10px'}} > 
            <Button  onClick={handleSaveAll} disabled={!isDirty} style={!isDirty ? {cursor: 'auto', pointerEvents: 'none',float:'right'} : {float:'right'}}>
              Envoyer
            </Button>
            
          </div>
          </div>  
        ) : (
          <div style={{display:'flex'}}>     
          <h4 style={{marginLeft:'10px',marginTop:'10px'}}>Absences</h4>
          <div style={{width:'100%'}} > 
            <Button className="button-6" disabled style={{ cursor: 'auto', pointerEvents: 'none' ,float:'right'}}>
              Envoyer
            </Button>
          </div>
          </div>

        );
      })
    );
  })
}



      </div>
      </Card.Header>
    </Card>






    {statut.map((stat)=>[
              stat.note === 0 ? (           
                 <div className="right-header">
         
            </div>):(          
                
                <Alert variant='danger' style={{marginTop:'10px',marginBottom:'-10px'}}>
               Les notes sont Bloquées
                  </Alert>
               
)
            ])} 









      {
      infos.map((info)=>{
        return info.type === "PAIE" ? (
        <>
        
        <div  style={{height:'50vh',overflow:'auto',marginTop:'20px'}}>
        <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Etablissement</th>
          <th>Statue</th>
        </tr>
      </thead>
      <tbody>
     
        {itemsFiltres.map(item => (
             <tr>
   <td>                {
    item.LIBETAB ==='' ? (item.LIBETABA):(item.LIBETAB)}</td>
   <td>   {item.note === 0 ? (<p style={{fontWeight:'700',color:'red'}}>Pas Envoyé</p>):(<p style={{fontWeight:'700',color:'green'}}>Envoyé</p>)}</td>
   </tr>
))}
         
      </tbody>
    </Table>



    </div>
      
      </>) : (    
             
             
             <>
             
             <div style={{height:'45vh',overflow:'auto'}}>

<Table striped bordered hover  size="sm" style={{marginTop:'20px'}}>
      <thead>
        <tr>
          <th>ADM</th>
          <th>matricule</th>
          <th>nom</th>
          <th>prenom</th>
          <th>absences</th>
          <th>note</th>

        </tr>
      </thead>
      <tbody>



{filterData(searchQuery).map(item => (
        <tr>
          <td>{item.ADM}</td>
          <td>{item.VMAT}</td>
          <td>{item.NOM}</td>
          <td>{item.PRENOM}</td>
          <td>         
              <Form >
          <Form.Group   controlId="exampleForm.ControlInput1" >
            <Form.Control 
                     type="number"
                     name="ABS"
                     min={'0'}
                     value={item.ABS}
                     onChange={e => handleInputChange(e, item.VMAT, 'ABS')}
            />
          </Form.Group>
    
        </Form>      
         </td>
          <td>     
                  <Form >
          <Form.Group   controlId="exampleForm.ControlInput2">
            <Form.Control

type="number"
name="NOTE"
min={'0'}
max={'40'}
value={item.NOTE}
onChange={e => handleInputChange(e, item.VMAT, 'NOTE')}
            />
          </Form.Group>
    
        </Form>       </td>

        </tr>
        ))}
      </tbody>
    </Table>

    </div>
</>
            
    )
      })
    }


    </>
  );
}

export default Note;
