import {BrowserRouter , Routes ,Route ,NavLink,Outlet} from 'react-router-dom';
import '../../css/navlink.css';

import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
export default function NavlinkSuivi(){

    return(
        
         <>
<Card>
      <Card.Header style={{display:'flex' , justifyContent:'center'}}>
        <Nav variant="pills" defaultActiveKey="#first">
          <Nav.Item>
          <NavLink to="/suivi/caisse" style={{textDecoration:'none'}}>
          <Nav.Link href="#first">Suivi de Caisse</Nav.Link>
            </NavLink>
          </Nav.Item>
          <NavLink to="/suivi/caisseA" style={{textDecoration:'none'}}>
          <Nav.Link href="#send">Sold annuel arreté</Nav.Link>
            </NavLink>
        </Nav>
      </Card.Header>
      </Card>
<Outlet/>
         </>
    )
}