import React, { useState, useEffect } from 'react';
import axios from 'axios';
import icon from '../../asset/Groupe.png';

export default function Eta(props) {
  const server = props.server;
  const [users, setUsers] = useState([]);
  const [popup, setPopup] = useState(false);
  const [popup2, setPopup2] = useState(false);
  const [message, setMessage] = useState('');
  const [show, setShow] = useState([]);
  const [show2, setShow2] = useState([]);
  const [codeEtablissement, setCodeEtablissement] = useState('');
  const [nomEtablissement, setNomEtablissement] = useState('');
  const [updatedData, setUpdatedData] = useState([]);

  useEffect(() => {
    // Fetch the list of users from the server
    axios
      .get(`${server}/user`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const closenewgroupe = () => {
    setPopup(false);
  };

  const newetaclose = () => {
    setPopup2(false);
  };

  const neweta = () => {
    setPopup2(true);
  };

  const handlePopupuser = (grouM) => {
    const selectedUser = users.find((user) => user.id === grouM);
    if (selectedUser) {
      setUpdatedData([selectedUser]);
      setPopup(true);
    }
  };

  const handleChange = (index, key, value) => {
    setUpdatedData((prevData) =>
      prevData.map((item, i) => (i === index ? { ...item, [key]: value } : item))
    );
  };

  const handleUpdate = () => {
    if (updatedData.length === 0) {
      console.error('No user data to update');
      return;
    }

    // Make an API request to update the data on the server
    const { CODETAB, LIBETAB, LIBETABA } = updatedData[0];
    axios
      .post(`${server}/update_data`, {
        code: CODETAB,
        nom: LIBETAB,
        nomArabe: LIBETABA,
      })
      .then((response) => {
        console.log(response.data.message);
        // Update successful, you may want to perform additional actions here
      })
      .catch((error) => {
        console.error('Error updating data:', error);
        // Handle error
      });
  };

  const handleValiderClick = () => {
    // Send the API request to the server to add new data
    axios
      .post(`${server}/addUser`, {
        codeEtablissement,
        nomEtablissement
      })
      .then((response) => {
        console.log('User added successfully:', response.data);
        // Clear the input values after successful insertion
        setCodeEtablissement('');
        setNomEtablissement('');
      })
      .catch((error) => {
        console.error('Error adding user:', error);
      });
  };

  return (
    <>
      <div className="sub-main">
        <div className="sub-header">
          <img style={{ width: '50px', height: '50px', marginRight: '20px', paddingLeft: '10px', paddingRight: '10px' }} src={icon} alt="Inbox" />
          <p style={{ fontSize: '20px', fontWeight: '500', marginRight: '20px' }}>Liste des Groupes</p>
          <button className="button-7" onClick={neweta}>
            Ajouter
          </button>
          <button style={{marginLeft:'10px'}} className="button-6">Supprimer</button>
        </div>
        <div className="right-header"></div>
      </div>

      <div className="mes">
        <div className="main-t">
          <div className="sel" style={{ width: '10%' }}></div>
          <div className="nom" style={{ width: '10%' }}>
            Code
          </div>
          <div className="nom" style={{ width: '80%' }}>
            Nom
          </div>
        </div>

        <div className="scrol">
          {users.map((user) => (
            <div className="main-s" key={user.id}>
              <div className="sel" style={{ width: '10%' }}>
                <input type="checkbox" />
              </div>
              <div className="nom" style={{ width: '10%' }}>
                {user.username}
              </div>
              <div className="nom" style={{ width: '75%' }}>
                {user.LIBETAB}
              </div>
              <div className="nom" style={{ width: '15%' }}>
                <button className="button-7" onClick={() => handlePopupuser(user.id)}>
                  Modifier
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {popup && (
        <div className="popup" style={{ marginTop: '-535px' }}>
          <div className="popup-content" style={{ height: 'auto' }}>
            <button
              className="button-6-2"
              style={{ float: 'right', background: 'red', color: '#fff' }}
              onClick={closenewgroupe}
            >
              X
            </button>
            <h3>Info Etablissement</h3>
            
            {updatedData.map((item, index) => (
              <div key={item.id} style={{ marginTop: '20px' }}>
                <p>Code :</p>
                <input className="obj" type="text" value={item.CODETAB} readOnly />
                <br />
                <p>Nom :</p>
                <input
                  className="obj"
                  type="text"
                  value={item.LIBETAB}
                  onChange={(e) => handleChange(index, 'LIBETAB', e.target.value)}
                />
                <br />
                <p>Nom arabe :</p>
                <input
                  className="obj"
                  type="text"
                  value={item.LIBETABA}
                  onChange={(e) => handleChange(index, 'LIBETABA', e.target.value)}
                />
              </div>
            ))}
            <button
              className="button-7"
              style={{ width: '100%', marginTop: '20px' }}
              onClick={handleUpdate}
            >
              Valider
            </button>
          </div>
        </div>
      )}

      {popup2 && (
        <div className="popup" style={{ marginTop: '-535px' }}>
          <div className="popup-content" style={{ height: 'auto' }}>
            <button
              className="button-6-2"
              style={{ float: 'right', background: 'red', color: '#fff' }}
              onClick={newetaclose}
            >
              X
            </button>
            <h2>Ajouter</h2>
            Code établissements:
            <input
              type="text"
              className="obj"
              value={codeEtablissement}
              onChange={(e) => setCodeEtablissement(e.target.value)}
            />
            <br />
            Nom de l'établissement
            <input
              type="text"
              className="obj"
              value={nomEtablissement}
              onChange={(e) => setNomEtablissement(e.target.value)}
            />
            <br />
            <button onClick={handleValiderClick} className="button-7">
              Valider
            </button>
          </div>
        </div>
      )}
    </>
  );
}

