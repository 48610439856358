import {BrowserRouter , Routes ,Route ,NavLink,Outlet} from 'react-router-dom';
import '../../css/navlink.css';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
export default function Navlinkg(){

    return(
        
         <>
    <Card>
      <Card.Header style={{display:'flex' , justifyContent:'center'}}>
      Liste des Gourpes
      </Card.Header>
      </Card>
<Outlet/>
         </>
    )
}