import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars,faBook, faBell, faRightFromBracket, faGear ,faTriangleExclamation,faEnvelope ,faEnvelopesBulk,faTable,faCashRegister,faUtensils,faSackDollar,faChartLine,faUsers,faSchool} from '@fortawesome/free-solid-svg-icons';
import '../css/header.css';
import profile from '../asset/Profile.jpg';
import { NavLink, useNavigate, Link,useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
export default function Header(props) {
    const server = props.server;
    const location = useLocation();
    const navigate = useNavigate();
    const [auth, setAuth] = useState(false);
    const [userList, setUserList] = useState([]);
    const [shownavbar, setnav] = useState(false);
    const [first, setFirst] = useState(1);
    const [items, setItems] = useState([]);
    const filteredItems = items.filter(item => item.im === 1 & item.lu === 0);
    const filteredUser = userList.filter(item => item.r_abs === 1 || item.r_note === 1);
    const filteredMessages = items.filter(item => item.im === 1 & item.formulaire === 0 & item.lu === 0);
    const filteredabs = userList.filter(item => item.r_abs == 1);
    const filterednote = userList.filter(item => item.r_note === 1);
    const togglePopup = () => {
        setnav(true);
      };
    
      const closePopup = () => {
        setnav(false);
      };

    const filteredFormulare = items.filter(item => item.im === 1 & item.formulaire === 1  & item.lu ===0);
    
    
    axios.defaults.withCredentials = true;



    useEffect(() => {
        axios.get(`${server}/info_u`)
            .then((res) => {
                setUserList(res.data);
            })
            .catch(err => console.error(err)); // Handle errors properly
    }, []);

    useEffect(() => {
        userList.forEach(user => {
            if (user.ft === 0 && first !== user.ft) {
                setFirst(user.ft);
            }
        });
    }, [userList, first]);

    useEffect(() => {
        if (first === 0) {
            navigate('/register');
        }
    }, [first, navigate]); // Added first and navigate as dependencies

    const handleLogout = () => {
        axios.get(`${server}/logout`)
            .then(res => {
                if (res.data.Status === 'Success') {
                   window.location.reload();
                } else {
                    alert('Erreur');
                }
            })
            .catch(err => console.error(err)); // Handle errors properly
    }


    return(
        
<>
<div style={{height:'74px',background:'rgba(3,78,145,1)',color:'#fff',display:'flex',alignItems:'center' , justifyContent:'space-between'}}><h4 className='headerr'>Direction de l'education</h4> <div style={{position:'absolute' , right:'20px'}}>
    {userList.map(item => ( 
<>  

<div style={{display:'flex'}}>
    <div>
    <img src={profile} style={{width:'45px',height:'45px',borderRadius:'10px',marginRight:'10px'}}/>
    </div>
    <div>
   
  {item.nom === '' ? (<p style={{padding:'0',margin:'0'}}>{item.LIBETAB}</p>):(<p style={{padding:'0',margin:'0'}}>{item.nom} {item.prenom}</p>)}
    <p style={{padding:'0',margin:'0',fontSize:'12px'}}>{item.role} {item.type}</p>
    
    </div>

</div>
    </>

))}</div></div>


</>
     /*    <>{!shownavbar == true ? (<></>) : (<><div className="sidebarr">
    <div style={{padding:'10px',textAlign:'center',color:'white',fontSize:'25px',fontWeight:'500',cursor:'pointer'}} onClick={closePopup}>X</div>
  <div className="nav2">
  <ul className="nav3">
  <li>
    
  <li>
                 {userList.map((info) => (
                    info.role == 'Chef de Service' ? (
                
                        <NavLink to="/Groups/L"  onClick={closePopup}><div className="filter"></div><div className="l2" ></div><span><FontAwesomeIcon icon={faUsers} style={{marginRight:"20px"}} />Gestion des groupes</span></NavLink>
                    ) :(
                        <></>
                    )
                ))}   
                </li>    
                 {userList.map((info) => (
                    info.role == 'Admin' ? (
                <>                        <li>
                <NavLink to="/Inbox/inbox"  onClick={closePopup}><div className="filter"></div><div className="l2"></div><span><FontAwesomeIcon icon={faEnvelopesBulk} style={{marginRight:"20px"}}/> Messagerie</span> </NavLink>   
                            </li>
                            
                            <NavLink to="/eta"  onClick={closePopup}><div className="filter"></div><div className="l2"></div><span><FontAwesomeIcon icon={faSchool} style={{marginRight:"20px"}} />Getion des établissemment </span></NavLink> </>
                       
                    ) :(
                        <>
        <li>
        <NavLink to="/Inbox/inbox"  onClick={closePopup} className={location.pathname === '/Inbox/inbox' || location.pathname === '/Inbox/send'|| location.pathname==='/Inbox/save' || location.pathname ==='/Inbox/trash' ? 'active' : ''}>
          <div className="filter"></div>
          <div className="l2"></div>
          <span>
            <FontAwesomeIcon icon={faEnvelopesBulk} style={{ marginRight: "20px" }} /> Messagerie
          </span>
        </NavLink>
      </li>
                <li>
                {userList.map((info) => (
                    info.role == 'Chef de Service' ? (
                
                    <NavLink to="/form"  onClick={closePopup}><div className="filter"></div><div className="l2"></div><span><FontAwesomeIcon icon={faTable} style={{marginRight:"20px"}} />Formulaires</span></NavLink>
                    ) :(
                        <></>
                    )
                ))} 
                  
                </li>
                
                <li>
                    <a href=""><div className="filter"></div><div className="l2"></div><span><FontAwesomeIcon icon={faChartLine} style={{marginRight:"20px"}} />Statistiques </span></a>   
                </li>             
           

                {userList.map((info) => (
                    info.cantine == 'oui' && info.role== 'Econome' || info.type ==='PROGRAMATION'  ? (
                
                      

                        <NavLink to='suiviCant'  onClick={closePopup}>
                       <a href=""><div className="filter"></div><div className="l2"></div><span><FontAwesomeIcon icon={faUtensils} style={{marginRight:"20px"}} />Suivi de Cantine </span></a>   
                    </NavLink>  



              
                    ) :(
                        <></>
                    )
                ))}   
     



     
              





   
                <li>
                {userList.map((info) => (
                    info.role == 'Econome' || info.type == 'finance' ? (
                
           



<li>
<NavLink to="/suivi/caisse"  onClick={closePopup} className={location.pathname === '/suivi/caisse' || location.pathname === '/suivi/caisseA' ? 'active' : ''}>
  <div className="filter"></div>
  <div className="l2"></div>
  <span>
    <FontAwesomeIcon icon={faCashRegister} style={{ marginRight: "20px" }} />Suivi de Caisse
  </span>
</NavLink>
</li>
                    ) :(
                        <></>
                    )
                ))} 





                {userList.map((info) => (
                    info.type == 'PAIE' || info.type ==='PRIMAIRE' && info.role == 'Directeur' ? (
                

                        <li>
                        <NavLink to="/salary/abs"  onClick={closePopup} className={location.pathname === '/salary/abs' || location.pathname === '/salary/note' ? 'active' : ''}>
                          <div className="filter"></div>
                          <div className="l2"></div>
                          <span>
                            <FontAwesomeIcon icon={faSackDollar} style={{ marginRight: "20px" }} /> Salaire
                          </span>
                        </NavLink>
                      </li>
                        ) :(
                        <></>
                    )
                ))}   
              
                </li>  





</>
                    )
                ))}   
                </li>  
    

  </ul>
  
  </div>
</div>










</>)

}




        
        <div className="head">
          <div className='first'>
          <div className='title'>      
          <FontAwesomeIcon icon={faBook} className='logo' />
         <div className="sub-title">
         <h3 className='titlee'>Direction de l'éducation</h3>
          <h3 className='sub'>De Béjaïa </h3>
         </div>
          </div>
          </div>

   <div className='burger' onClick={togglePopup} style={{cursor:'pointer'}}>
   <FontAwesomeIcon icon={faBars} className='logo' style={{marginTop:'9px'}} />
   </div>




       <div>


       </div>

       <div className='sec'>
       <div className="Profile">
       <FontAwesomeIcon icon={faBell} className='not' />
          <div className='imp'>
<img src={profile} />
          </div>
          
          {userList.map((user , key)=>
              user.nom === ''  ? ( 
              
                user.LIBETAB === '' ? (              <div className='name'> <p className='f'>{user.LIBETABA}</p>
                <p className='s'>{user.role} {user.type}</p> 
              
                </div>):(<div className='name'> <p className='f'>{user.LIBETAB}</p>
          <p className='s'>{user.role} {user.type}</p> 
        
          </div>)
              
              
              

          ) : (

   
           <div className='name'> <p className='f'>{user.nom} {user.prenom}</p>
          <p className='s'>{user.role} {user.type}</p> 
          </div>
          )
          )}
        <Link to="/Setting" className='icone'> <div className='setting' ><FontAwesomeIcon icon={faGear} /></div></Link>
          <div className='logout' onClick={handleLogout}><FontAwesomeIcon icon={faRightFromBracket} /></div>

          </div>
        
          </div>
       </div>

        
          </>
        */



    )
}