import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import moment from 'moment';
import { Link, useNavigate, useParams, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';
import icon from '../../asset/formulaire.png';
import Card from 'react-bootstrap/Card';
import * as XLSX from "xlsx";
import Button from 'react-bootstrap/Button';
import { saveAs } from "file-saver";
import { CDBTable, CDBTableHeader, CDBTableBody, CDBContainer } from 'cdbreact';
export default function For(props) {
  const server = props.server;
  const Navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [formData, setFormData] = useState([]);

const [title,setTilte]=useState('Message');
const { id_m } = useParams();
  axios.defaults.withCredentials = true;


  useEffect(() => {
    axios.get(`${server}/forval/${id_m}`).then((res) => {
        setFormData(res.data);
    });
  }, []);
  const uniqueNoms = [...new Set(formData.map(item => item.nom))];

  // Créer un objet pour stocker les paires "nom"-"valeur" correspondantes
  const nomValeurPairs = {};
  formData.forEach(item => {
    nomValeurPairs[item.nom] = nomValeurPairs[item.nom] || [];
    nomValeurPairs[item.nom].push(item.valeur);
  });



  const tableRef = useRef();

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.table_to_sheet(tableRef.current);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Générer le fichier Excel
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Enregistrer et télécharger le fichier Excel
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data,`formulaire.xlsx`);
  };

  return (
    <>


    <Card style={{marginTop:'20px'}}>
      <div style={{marginTop:'20px',marginRight:'20px'}}><Button variant="primary" onClick={exportToExcel} style={{float:'right'}}>Excel</Button></div>
      <Card.Body>
      <CDBContainer>


      <table class="table" ref={tableRef}>
  <thead
  >
         <tr >
       {uniqueNoms.map((nom, index) => (
         <th key={index} scope="col">{nom}</th>
   

       ))}
             <th scope="col">Etablissement</th>
     </tr>
  </thead>
  <tbody>
  {formData.map((item, rowIndex) => (
      <>
       <tr key={rowIndex}>
         {uniqueNoms.map((nom, colIndex) => (
           <td key={colIndex}>
             {nomValeurPairs[nom].shift()}
           </td>
          
         ))}
          <td>{item.LIBETAB}</td>
       </tr>
       </> ))}
  </tbody>
</table>
    </CDBContainer>
      </Card.Body>
    </Card>
      </>
  )
      



 
    
}
