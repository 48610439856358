import '../../css/Form.css';
import { BrowserRouter, Routes, Route, NavLink, Outlet, useNavigate, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import '../../css/messagerie.css';
import icon from '../../asset/formulaire.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRepeat, faUsers, faTable, faEye, faEyeSlash, faCheck,faDownLong} from '@fortawesome/free-solid-svg-icons';
import * as XLSX from "xlsx";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import {
  CDBContainer
  
} from 'cdbreact';

export default function FormSend(props){
    const [showPopup, setShowPopup] = useState(false);
    const [popupDataLength, setPopupDataLength] = useState(0);
    const [users, setUsers] = useState([]);
    const [down, setDown] = useState([]);
    const [excel, setExcel] = useState([]);
    const [load , setLoad] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
  const server= props.server;

      useEffect(() => {
        // Mettre à jour l'état lorsque les props changent
        setUsers(props.mess);
      }, [props.mess]);
    


      useEffect(() => {
        const fetchDatadown = async () => {
          try {
            const res = await axios.get(`${server}/form_down`);
            setDown(res.data);
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchDatadown();
      }, []);

  console.log(down);

  const handleExcel = (grouM) => {
    const duplicats = down.filter((donnees) => donnees.grou_m === grouM);
    
    const nomValeurPairs = {};
    duplicats.forEach((item) => {
      nomValeurPairs[item.nom] = nomValeurPairs[item.nom] || [];
      nomValeurPairs[item.nom].push(item.valeur);
    });

    const uniqueNoms = [...new Set(duplicats.map(item => item.nom))];
    // Convertir les données au format Excel
    const worksheetData = [
      uniqueNoms,
      ...duplicats.map((item) => uniqueNoms.map((nom) => nomValeurPairs[nom].shift())),
    ];
console.log(worksheetData);
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Créer un classeur (workbook) et ajouter la feuille de calcul (worksheet) dedans
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Feuille1");

    // Générer un tampon (buffer) pour le classeur
    const excelBuffer = XLSX.write(workbook, { type: "array", bookType: "xlsx" });

    // Convertir le tampon en Blob
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Créer une URL à partir du Blob
    const url = URL.createObjectURL(blob);

    // Créer un élément d'ancre temporaire pour déclencher le téléchargement
    const a = document.createElement("a");
    a.href = url;
    a.download = "donnees.xlsx";

    // Ajouter l'élément d'ancre au document et cliquer dessus pour déclencher le téléchargement
    document.body.appendChild(a);
    a.click();

    // Supprimer l'élément d'ancre temporaire
    document.body.removeChild(a);
  };
 
      const [popupData, setPopupData] = useState([]);
      const [popupData2, setPopupData2] = useState([]);

    
      const handlePopupOpen = (grouM) => {
        const duplicates = users.filter((donnees) => donnees.grou_m === grouM);
    
        setPopupData(duplicates);
        setPopupDataLength(duplicates.length);
    
        setShow(true);
    
      };
    
      const handlePopupClose = () => {
        setShowPopup(false);
      };


      const uniqueData = users.filter((donnees, index, self) => {
        if (donnees.grou_m === null) {
          return true; // Inclure les doublons où grou_m est null
        } else {
          return self.findIndex((d) => d.grou_m === donnees.grou_m) === index;
        }
      });


      const handleRelancer = async (messageId) => {
        try {
          // Update the message status here
          await axios.post(`${server}/relance/${messageId}`, {
            lu: '1' // Update the 'lu' field to '1'
          });
    
          // Refetch the updated data from the server
          const res = await axios.get(`${server}/messagesS`);
          setUsers(res.data);
    
          // Optionally show a success message to the user
          alert('Message status updated successfully.');
        } catch (error) {
          console.error(error);
          // Show an error message if the update fails
          alert('Failed to update message status.');
        }
      };

    return(
        
         <>


<Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Liste</Modal.Title>
        </Modal.Header>
        <Modal.Body>              
          {popupData.map((donnees) => (
                <div key={donnees.id_m} style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginRight:'20px' }}>
                  <p>{donnees.CODETAB} {donnees.LIBETAB}</p>
                  <div>                 {
                    donnees.lu === 1 ? (
                      <Badge bg='success' pill>
                      <FontAwesomeIcon icon={faEye} />
                      </Badge>

                    ) : (
                      <Badge bg='danger' pill>
                      <FontAwesomeIcon icon={faEyeSlash} />
                      </Badge>

                    )

                  }
                  {  donnees.formulaire === 2 ?(<>
                  
                  <Badge bg='success'>
                  <FontAwesomeIcon style={{ color: '#fff'}} icon={faCheck} />
                  </Badge>
                  </>):(<></>)}
                
                </div>

                </div>
              ))}
              </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
           Fermer
          </Button>

        </Modal.Footer>
      </Modal>
      
      <Card style={{marginTop:'20px'}}>

<Card.Body>
  <Card.Title>

</Card.Title>
  <CDBContainer style={{maxHeight:'65vh'}}> 

    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
    <div><p style={{fontSize:'20px',fontWeight:'500'}}>Fromulaires Envoyés </p></div>
<div style={{display:'flex'}}> <NavLink to="/form/NewForm"><Button variant="primary"  className="mb-3">Nouveau Fromulaire</Button></NavLink> 
<Button variant="danger"  className="mb-3 ms-3">Supprimer </Button>{''}</div>


</div>

<div >
<Form>
<Form.Group className="mb-3" controlId="formGroupEmail">
  <Form.Control type="text" placeholder="Rechercher les messages..." />
</Form.Group>
</Form>

</div>

  <ListGroup as="ul" >




{load ? ( <> <div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'90%'}}>
    <svg className="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
     <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
    </div></>) : 
    ( 

    users =='' ? (<><h3 style={{color:'rgba(0,0,0,0.3)' ,display:'flex', alignItems:'center',justifyContent:'center',height:'90%' }}>-  aucun message  -</h3></>) : (
    
    <>         
    
    

{uniqueData.map((user) => {
    const duplicates = users.filter((donnees) => donnees.grou_m === user.grou_m);
    const popupDataLength = duplicates.length;
    const duplicatess = duplicates.filter((donnees) => donnees.lu =='1');
    return (<>
    
 <ListGroup.Item
as="li"
className={`d-flex justify-content-between align-items-start mail ${user.lu === 1 ? 'luu' : ''}`}
key={user.id}
style={{listStyle:'none'}}
>
    <Form.Check
      inline
      type="checkbox"
    />
<div className="ms-3 me-auto">
<div className="fw-bold">{user.groupe !== '' ? user.nom : user.LIBETAB}</div>
{user.objet}

</div>
<div className='btnn btnsend'>




  {user.grou_m === null ? (<> 
  
    <Button bg='primary' size="sm"  onClick={() => handleRelancer(user.id_m)} > 
<FontAwesomeIcon icon={faRepeat} />
  </Button>{' '}
</>):(<>
  <Button bg='primary' size="sm"    onClick={() => handleRelancer(user.grou_m)}  > 
<FontAwesomeIcon icon={faRepeat} />
  </Button>{' '}
</>)}      
</div>
<div>

{user.groupe == '' ? (
            user.lu == '1' ? (
       
  <Badge bg="success" pill style={{float:'right'}}>
  <FontAwesomeIcon  icon={faEyeSlash} />
  </Badge>         
 ) : (
<Badge bg="danger" pill style={{float:'right'}}>
<FontAwesomeIcon  icon={faEyeSlash} />
</Badge>  
            )
          ) : (
            <>

            <Badge bg="success" pill style={{float:'right' ,display:'flex',justifyContent:'center',alignItems:'center'}}  onClick={() => handlePopupOpen(user.grou_m)}>
    {duplicatess.length}/{popupDataLength} 
              <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faUsers} />
  </Badge>  
 
            </>
          )}
         {user.groupe == '' ? (
            user.lu == '1' ? (
              
        <Badge bg="danger"> <FontAwesomeIcon style={{ color: '#fff' }} icon={faEyeSlash} /></Badge>

          
            ) : (
      <></>
            )
          ) : (
            <>
          
          {duplicatess.length == 0 ? (<></>) : (<> <Badge onClick={() => handleExcel(user.grou_m)}><FontAwesomeIcon icon={faDownLong} /></Badge></>) }

            </>         )}
<br></br>
<div style={{fontSize:'13px' , color:'rgba(0,0,0,0.5)' ,fontWeight:'500'}}>{user.date}</div>
</div>
</ListGroup.Item>

    
    </>)

  })}

</>


))   
     }


</ListGroup>
</CDBContainer>

</Card.Body>
</Card>








         </>
    )
}