import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../src/css/login.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


import {
  CDBInput,
  CDBCheckbox,
  CDBCard,
  CDBCardBody,
  CDBIcon,
  CDBBtn,
  CDBLink,
  CDBContainer,
  CDBSpinner,
} from 'cdbreact';
import { faL } from "@fortawesome/free-solid-svg-icons";
function Login(props) {
  const server = props.server;
  const navigate = useNavigate();

  const [values, setValues] = useState({
    user: "",
    password: ""
  });

  const [auth, setAuth] = useState(false);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [messagee, setMessagee] = useState("");
  const [load,setLoad]= useState(false);
  const [show,setShow]= useState(true);

  const showT = () => {
    setShow(!show);
  };
  axios.defaults.withCredentials = true;

  useEffect(() => {
    axios.get(`${server}`)
      .then(res => {
        if (res.data.Status === "Success") {
          setAuth(true);
          setName(res.data.name);
          window.location.reload();
        } else {
          setAuth(false);
          setMessage(res.data.Message);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        setLoad(true);
      const res = await axios.post(`${server}/login`, values);
      const name = res.data.name;
      if (res.data.Status === "Success") {
        navigate('/Inbox/inbox');
        window.location.reload();

      
      } else {
        setMessagee(res.data.Message);
        setLoad(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit2= async (e) => {
    e.preventDefault();
    try {
        setLoad(true);
      const res = await axios.post(`${server}/login2`, values);
      const name = res.data.name;
      if (res.data.Status === "Success") { 
        navigate('/Inbox/inbox');
        window.location.reload();

      
      } else {
        setMessagee(res.data.Message);
        setLoad(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
if (auth) {
    navigate('/Inbox/inbox');
  }

  return (
    <>
<div style={{width:'100%', height:'100vh',display:'flex',justifyContent:'center',background:'#f8f9fa'}}>
          <div class="wrapper">
          <div class="title">
             Direction de l'Education
          </div>
{show ? (          <form onSubmit={handleSubmit}>
 
 <p style={{color:'red',textAlign:'center',height:'10px',marginTop:'-5px'}}>{messagee}</p>
  
    <div class="field">
       <input type="text" required  onChange={e => setValues({ ...values, user: e.target.value })}/>
       <label>Nom d'utilisateur</label>
    </div>
    <div class="field">
       <input type="password" required  onChange={e => setValues({ ...values, password: e.target.value })}/>
       <label>Password</label>
    </div>
    <div class="content">
       <div class="checkbox">
          <input type="checkbox" id="remember-me"/>
          <label for="remember-me">Se souvenir de moi </label>
       </div>
      
       <div onClick={showT} class="pass-link" style={{marginTop:'20px' ,textAlign:'center'}}>
          <a href="#">Mot de passe oublier</a>
       </div>

    </div>
    <div class="field">

       <button className='login-btn'>     
       { load == true ?(
 <>
 <svg className="spinner" width="20px" height="20px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
<circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
</svg>
 </>
       ):(
           <>Se Connecter</>
       )}      



 </button>
    </div>
 </form>):(<> <p onClick={showT}>retoure</p><form onSubmit={handleSubmit2}>
 
 <p style={{color:'red',textAlign:'center',height:'10px',marginTop:'-5px'}}>{messagee}</p>
  
    <div class="field">
       <input type="text" required  onChange={e => setValues({ ...values, user: e.target.value })}/>
       <label>Nom d'utilisateur</label>
    </div>
    <div class="field">
       <input type="text" required  onChange={e => setValues({ ...values, password: e.target.value })}/>
       <label>email</label>
    </div>
    <div class="content">
       <div class="checkbox">
          <input type="checkbox" id="remember-me"/>
          <label for="remember-me">Se souvenir de moi </label>
       </div>
      
       <div onClick={showT} class="pass-link" style={{marginTop:'20px' ,textAlign:'center'}}>
          <a href="#">Mot de passe oublier</a>
       </div>

    </div>
    <div class="field">

       <button className='login-btn'>     
       { load == true ?(
 <>
 <svg className="spinner" width="20px" height="20px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
<circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
</svg>
 </>
       ):(
           <>Se Connecter</>
       )}      



 </button>
    </div>
 </form></>)}
       </div>
       </div>
   
    
                </>

  );
}

export default Login;
