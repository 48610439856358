import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import icon from '../../asset/Groupe.png';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
export default function Groupe(props) {
  const server =props.server;
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [group, setGroup] = useState([]);
  const [popup, setPopup] = useState(false);
  const [popup2, setPopup2] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [show3, setShow3] = useState([]);
  const [show2, setShow2] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);


  useEffect(() => {
    axios.get(`${server}/show_user`)
      .then((response) => {
        setShow3(response.data);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, []);
  useEffect(() => {
    axios.get(`${server}/user`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const newGroup = () => {
    setPopup(true);
  };

  const closeNewGroup = () => {
    setPopup(false);
    setMessage('');
    setSelectedUsers([]);
  }

  const closeNewGroup2 = () => {
    setPopup2(false);
  }

  const fetchData2 = async () => {
    try {
      const res = await axios.get(`${server}/groupeL`);
      setGroup(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData2();
  }, []);

  const handleUserSelection = (userID) => {
    if (selectedUsers.some(user => user.id === userID)) {
      setSelectedUsers(selectedUsers.filter(user => user.id !== userID));
    } else {
      setSelectedUsers([...selectedUsers, { id: userID }]);
    }
  };
  
  const handleCreateGroup = () => {
    if (selectedUsers.length > 0) {
      if (groupName) {
        if (users.length === 0) {
          console.log("Users data is not available yet.");
          return;
        }
        const selectedUserIDs = selectedUsers.map((selectedUser) => selectedUser.id);
        axios
          .post(`${server}/groups`, {
            name: groupName,
            users: selectedUserIDs,
          })
          .then((response) => {
            console.log('Group created:', response.data);
            setMessage('Groupe Créer');
            fetchData2();
          })
          .catch((error) => {
            console.error('Error creating group:', error);
          });
      } else {
        setMessage(
          <p style={{ color: 'red', padding: '10px 10px', background: '#f8d7da', borderRadius: '10px', marginLeft: '20px' }}>
            Veuillez saisir le nom du groupe
          </p>
        );
      }
    } else {
      setMessage(
        <p style={{ color: 'red', padding: '10px 10px', background: '#f8d7da', borderRadius: '10px', marginLeft: '20px' }}>
          Aucun utilisateur sélectionné
        </p>
      );
    }
  };
  
  
  const handleGroupSelection = (groupID) => {
    if (selectedGroups.includes(groupID)) {
      setSelectedGroups(selectedGroups.filter(id => id !== groupID));
    } else {
      setSelectedGroups([...selectedGroups, groupID]);
    }
  };
  
  const handleDeleteSelectedGroups = async () => {
    if (selectedGroups.length > 0) {
      try {
        await axios.post(`${server}/deleteGroups`, {
          groups: selectedGroups
        });
        setSelectedGroups([]);
        fetchData2(); // Refresh data after deletion
      } catch (error) {
        console.error('Error deleting groups:', error);
      }
    }
  };
  
  

  const handlePopupuser = (grouM) => {
    const duplicates = show3.filter((donnees) => donnees.group_id === grouM);
    setShow2(duplicates);
    handleShow4();
  };
console.log(show3);
  return (
    <>


<Card style={{marginTop:'20px'}}>   
      <Card.Header>
 <div style={{display:'flex'}}>
 <img style={{ width: 'auto', height: '50px', marginRight: '20px', paddingLeft: '10px', paddingRight: '10px' }} src={icon} alt="Inbox" />
          <p style={{ fontSize: '20px', fontWeight: '500', marginRight: '20px' }}>Liste des Groupes</p>
          <Button  onClick={handleShow}>Nouveau Groupe</Button>
          <Button variant='secondary' style={{marginLeft:'10px'}}  onClick={handleDeleteSelectedGroups}>
  Supprimer
</Button>
 </div>



    
      </Card.Header>
    </Card>



    <Table striped bordered hover style={{marginTop:'20px'}}>
      <thead>
        <tr>
          <th></th>
          <th>Nom</th>

        </tr>
      </thead>
      <tbody>
      {group.map(item => (
        <tr>
          <td style={{width:'5%'}}><input
        type="checkbox"
        checked={selectedGroups.includes(item.id)}
        onChange={() => handleGroupSelection(item.id)}
      /></td>
          <td style={{width:'85%'}}>{item.name}</td>
          <td style={{width:'10%'}}><Button onClick={() => handlePopupuser(item.id)}>Voir</Button></td>

        </tr>

))}


      </tbody>
    </Table>


  







      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Nouveau Groupe</Modal.Title>
        </Modal.Header>
        <Modal.Body><Form>
          <Form.Group   controlId="exampleForm.ControlInput1">
            <Form.Control type="text"                  placeholder="Nom du Groupe"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}></Form.Control>
          </Form.Group>
          <Modal.Title>Utilisateur</Modal.Title>







    <div style={{height:'45vh',overflow:'auto'}}>
    {users.map((user) => (
  <tr key={user.id}>
    <td>
    <input
                          type="checkbox"
                          checked={selectedUsers.some(selectedUser => selectedUser.id === user.id)}
                          onChange={() => handleUserSelection(user.id, user.username)}
                        />
    </td>
    <td>{user.username}</td>
    <td>{user.LIBETAB}</td>
    <td>{user.role}</td>

  </tr>
))}
    </div>








        </Form></Modal.Body>
        <Modal.Footer>

          {groupName && selectedUsers.length !== 0 ? (<>          <Button variant="primary" onClick={handleCreateGroup} >
      Valider
          </Button></>):(<>          <Button disabled variant="primary">
      Valider
          </Button></>)}

        </Modal.Footer>
      </Modal>

      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Liste des utilisateurs</Modal.Title>
        </Modal.Header>
        <Modal.Body> <div style={{height:'50vh',overflow:'auto'}}>{show2.map((item) => (
              <p key={item.id}>- {item.username} {item.LIBETAB}</p>
            ))}</div></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Fermer
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}
