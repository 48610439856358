import {BrowserRouter , Routes ,Route ,NavLink,Outlet} from 'react-router-dom';
import '../../css/navlink.css';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
export default function Navlinke(){






    

    
  
    return(
        
         <>
         <div className="nav">
  <ul className="bar"> 
    <li className="Link">
    <NavLink to="">
    <div className="lh"></div>
      <span>Liste des établissemment</span>
      </NavLink>
    </li>
  </ul>
</div>
<Outlet/>
         </>
    )
}