import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import icon from '../../asset/inbox.png';
import '../../css/messagerie.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBookOpen, faUsers,faCircleCheck,faFilter,faTable,faTriangleExclamation,faEllipsisVertical,faTrash,faFloppyDisk} from '@fortawesome/free-solid-svg-icons';
import ListGroup from 'react-bootstrap/ListGroup';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import {NavLink,useLocation} from 'react-router-dom';

import {
  CDBContainer,
  
} from 'cdbreact';


const RMessage = (props) => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [filtre, setFiltre] = useState(false);
  const [filtre2, setFiltre2] = useState(false);
  const [load , setLoad] = useState(props.loadm);
  
  const [items, setItems] = useState([]);
const server = props.server;

  useEffect(() => {
    // Mettre à jour l'état lorsque les props changent
    setItems(props.mess);
  }, [props.mess]);






  const handleCheckboxChange = (itemId) => {
    setItems(prevItems => {
      return prevItems.map(item => {
        if (item.id_m === itemId) {
          return { ...item, checked: !item.checked };
        }
        return item;
      });
    });
  };
  

  function launch_toast() {
    var x = document.getElementById("toast")
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", "");}, 2000);
}

  const handleDeleteChecked = () => {
    const checkedItems = items.filter(item => item.checked);
  
    // Obtenez uniquement les IDs des éléments cochés
    const checkedItemIds = checkedItems.map(item => item.id_m); // Utilisez item.id au lieu de item.id_m
  
    axios.post(`${server}/trash`, checkedItemIds)
      .then(response => {
        setItems(prevItems => {
          return prevItems.filter(item => !item.checked);
        });
      })
      .catch(error => {
        console.error('Erreur lors de la suppression des éléments :', error);
      });
  };

  
  const handleDeleteChecked2 = (id_m) => { 
    axios.post(`${server}/trash/${id_m}`,)
      .then(response => {
        console.log('suppp');
      })
      .catch(error => {
        console.error('Erreur lors de la suppression des éléments :', error);
      });
  };

  const handleSaveChecked2 = (id_m) => { 
    axios.post(`${server}/save/${id_m}`,)
      .then(response => {
        console.log('suppp');
      })
      .catch(error => {
        console.error('Erreur lors de la suppression des éléments :', error);
      });
  };

  
  const handleSaveChecked = () => {
    const checkedItems = items.filter(item => (item.save === 0) || (item.checked && item.save === 0));
  console.log(checkedItems);
    // Obtenez uniquement les IDs des éléments cochés
    const checkedItemIds = checkedItems.map(item => item.id_m); // Utilisez item.id au lieu de item.id_m
  
    axios.post(`${server}/save`, checkedItemIds)
      .then(response => {
  
          setMessage('message enregistré');
          launch_toast();
         
  
      })
      .catch(error => {
        console.error('Erreur lors de la suppression des éléments :', error);
      });
  };

  const [groupeChecked, setGroupeChecked] = useState(false);
  const [luChecked, setLuChecked] = useState(false);
  const [nluChecked, setNLuChecked] = useState(false);
  const [filterText, setFilterText] = useState('');







  
  const filteredMessagess = items.filter(message => {
    const messageMatch = message.message.toLowerCase().includes(filterText.toLowerCase());
    const objetMatch = message.objet.toLowerCase().includes(filterText.toLowerCase());
    const libetabeMatch = message.LIBETAB.toLowerCase().includes(filterText.toLowerCase());
    const codetabMatch = message.CODETAB.toLowerCase().includes(filterText.toLowerCase());

    if (groupeChecked && luChecked) {
      return (message.groupe !== '' && message.lu === 0) && (messageMatch || objetMatch || libetabeMatch || codetabMatch);
    } else if (groupeChecked) {
      return message.groupe !== '' && (messageMatch || objetMatch || libetabeMatch || codetabMatch);
    } else if (luChecked) {
      return message.lu === 0 && (messageMatch || objetMatch || libetabeMatch || codetabMatch);
    } else {
      return messageMatch || objetMatch || libetabeMatch || codetabMatch;
    }
  });



  const toggleFiltre= () => {
    setFiltre(!filtre);

  };
  const toggleFiltre2= () => {
    setFiltre2(!filtre2);

  };



  
  return (
    <>

        <Card style={{marginTop:'20px'}}>
      <Card.Body>
        <Card.Title>
        <Card>
      <Card.Body>  

          <div style={{display:'flex',alignItems:'center'}}>
          <img style={{ width: '50px', height: 'auto', marginRight: '10px' }} src={icon} alt="Inbox" />
            <h5>Message Reçus</h5>
            <div style={{display:'flex',right:'10px',position:'absolute'}} className='btnns'>
            <NavLink to="/Inbox/message" style={{textDecoration:'none'}}>
            <Button variant="primary"  className=" ms-3">Nouveau Message </Button>{' '}
</NavLink>
        <Button variant="primary"  className=" ms-3" onClick={handleSaveChecked}>Enregister</Button>{' '}
<Button variant="secondary"  className=" ms-3" onClick={handleDeleteChecked}>Supprimer </Button>{''}

              </div>     
</div></Card.Body>
    </Card>

</Card.Title>
        <CDBContainer style={{maxHeight:'65vh'}}> 


<div >
<Form>
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Control type="text"  value={filterText} onChange={(e) => setFilterText(e.target.value)} placeholder="Rechercher les messages..." />
      </Form.Group>
    </Form>

</div>

<ListGroup as="ul" style={{ height: '45vh', overflow: 'auto' }}>
  {load ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90%' }}>
      <svg className="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
    </div>
  ) : (
    filteredMessagess.length ===0 ? (
      <h5 style={{ color: 'rgba(0,0,0,0.3)', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90%' }}>- Aucun message -</h5>
    ) : (
      <>
{filteredMessagess.map(item => (
  
  <Link
    to={`/Inbox/mes/${item.id_m}`}
    style={{ textDecoration: 'none',position:'relative',zIndex:'0'}}
    key={item.id}
  >
    <ListGroup.Item
      as="li"
      className={`d-flex justify-content-between align-items-start mail ${item.lu === 1 ? 'luu' : ''}`}
      key={item.id}
      style={{ listStyle: 'none' }}
    >
      <div
        style={{ zIndex: '0', cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation(); // Arrête la propagation de l'événement
        
        }}
      >
        <Form.Check
          inline
          type="checkbox"
          checked={item.checked}
          onChange={() => handleCheckboxChange(item.id_m)}
          style={{ zIndex: '-1' }} // Appliquer un style à la case à cocher si nécessaire
        />
      </div>
      <div className="ms-3 me-auto">
        <div className="fw-bold">{item.im=== 1 ? (<FontAwesomeIcon icon={faTriangleExclamation} style={{color:'red'}} />):(<></>)}{item.LIBETAB}</div>
        {item.objet}
      </div>
      <div className='btnn' >
{item.save === 0 ? (      <Button 
  size="sm"  
  onClick={(e) => {
    e.stopPropagation(); // Arrête la propagation de l'événement
    e.preventDefault();
    handleSaveChecked2(item.id_m)
    // Ici, item.id_m doit être l'ID de l'élément que vous souhaitez mettre à jour
  }} 
>
  
          <FontAwesomeIcon icon={faFloppyDisk} />
        </Button>):(
        <Button 
  size="sm"  
  onClick={(e) => {
    e.stopPropagation(); // Arrête la propagation de l'événement
    e.preventDefault();
 
    // Ici, item.id_m doit être l'ID de l'élément que vous souhaitez mettre à jour
  }}disabled
>
  
          <FontAwesomeIcon icon={faFloppyDisk} />
        </Button>)}
        <Button variant="danger" size="sm" className='ms-3'   onClick={(e) => {
    e.stopPropagation(); // Arrête la propagation de l'événement
    e.preventDefault();
    handleDeleteChecked2(item.id_m)
    // Ici, item.id_m doit être l'ID de l'élément que vous souhaitez mettre à jour
  }}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>{' '}
      </div>
      <div>
        <Badge bg="danger" pill style={{ float: 'right' }}>
          {item.lu === 1 ? (<></>) : (<>.</>)}
        </Badge>
        <br></br>
        <div style={{ fontSize: '13px', color: 'rgba(0,0,0,0.5)', fontWeight: '500' }}>{item.date}</div>
      </div>
    </ListGroup.Item>
  </Link>
))}

      </>
    )
  )}
</ListGroup>

    </CDBContainer>

      </Card.Body>
    </Card>


{filtre && (
  <div style={{borderRadius:'10px',display:'flex',justifyContent:'space-around',alignItems:'center',height:'20px',marginTop:'20px',background:'#fff'}} className='tt'>
  <FontAwesomeIcon icon={faFilter} style={{fontSize:'25px',marginRight:'-85px',color:'#1097EC'}} />
  <p style={{fontSize: '20px', fontWeight: '500'}}>Filtre</p>
  <div>
        <input className='obj2' style={{width:'200px'}} type="text" value={filterText} onChange={(e) => setFilterText(e.target.value)} placeholder="Rechercher les messages..." />
      </div>
  <div>
    <label>
      <input type="checkbox" checked={groupeChecked} onChange={() => setGroupeChecked(!groupeChecked)} />
      Groupe
    </label>
  </div>
  <div>
    <label>
      <input type="checkbox" checked={luChecked} onChange={() => setLuChecked(!luChecked )} />
       Lu
    </label>
  </div>
  <div>
    <label>
      <input type="checkbox" checked={nluChecked } onChange={() => setNLuChecked(!nluChecked )} />
    Non lu
    </label>
  </div>
</div>
)}



  
      
    </>
  );
};

export default RMessage;
