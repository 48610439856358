import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx'
import icon from '../../asset/Caisse.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExcelJS from 'exceljs';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { faBook, faBookOpen, faUsers,faCircleCheck,faFilter,faTable,faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';


function SuiviA(props) {
  const server = props.server;
  const [infos, setInfo] = useState([]);
  const [Liste , setListe] =useState([]);
  const [abs, setAbs] = useState();
  const [statut,setSat] =useState([]);
  const [load,setLoad] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  const [show, setShow] = useState(false);

  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [values, setValues] = useState({
    compte511: '',
    compte512: '',
    compte513: '', // Le total sera calculé automatiquement
    total: '',
  });

  
function launch_toast() {
  var x = document.getElementById("toast")
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", "");}, 5000);
}

  
  
  useEffect(() => {
  
    axios.get(`${server}/info_u`).then((res) => {
      setInfo(res.data);
      setLoad(false);
      });
    }, []);
  useEffect(() => {
    // Récupérez les données existantes de la table SuiviStat depuis votre serveur Node.js
    axios.get(`${server}/getSuiviStatA`).then((response) => {
      const data = response.data[0]; // Supposons que vous récupérez une seule ligne de données
      if (data) {
        setValues({
          compte511: data.COMPTE511,
          compte512: data.COMPTE512,
          compte513: data.COMPTE513,
          total: data.COMPTE511 + data.COMPTE512 + data.COMPTE513, // Calculez le total ici
        });
      }
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  

  useEffect(() => {
    calculateTotal();
  }, [values.compte511, values.compte512 ,values.compte513]);
  
 
  

  
  useEffect(() => {
    calculateTotal(); // Appelez calculateTotal au chargement initial
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Envoyez les données mises à jour au serveur Node.js pour les enregistrer dans la base de données
    await axios.post(`${server}/updateSuiviStatA`, values);
    alert('Données mises à jour avec succès !');
  };




  
  useEffect(() => {
    if (infos.length > 0 && infos[0].role === "Chef de Service") {
      axios.get(`${server}/ListeStatA`).then((res) => {
        setListe(res.data);
      });
    }
  }, [infos]);
  
   


  const calculateTotal = () => {
    const {compte511, compte512, compte513 } = values;
  
    // Assurez-vous que balance et cashBalance sont des nombres
    const compte511val = parseFloat(compte511) || 0;
    const compte512val = parseFloat(compte512) || 0;
    const compte513val = parseFloat(compte513) || 0;

  
    const total = compte511val + compte512val + compte513val;
  
    setValues((prevValues) => ({
      ...prevValues,
      total: total.toString(),
    }));
  };

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Liste');
  
    // Define the title and data to export (dans votre cas, Liste)
    const title = [
      ['الجمهورية الجزائرية الديمقراطية الشعبية'],
      ['وزارة التربية الوطنية'],
      ['مديرية التربية لولاية بجاية'],
  
    ];
    const data = Liste.map((list) => [
      list.TOT,
      list.COMPTE513,
      list.COMPTE512,
      list.COMPTE511,
      list.LIBETABA,
      list.CODETAB,
    ]);
  
    // Fusionner et centrer les cellules pour le titre
    worksheet.mergeCells('A1:F1');
    worksheet.mergeCells('A2:F2');
    worksheet.mergeCells('A3:F3');
    worksheet.mergeCells('A4:F4');
    worksheet.mergeCells('A5:F5');
    worksheet.mergeCells('A6:F6');
    worksheet.mergeCells('A7:F7');
    worksheet.mergeCells('A8:F8');
    worksheet.mergeCells('A9:F9');
    worksheet.mergeCells('A10:F10');
    worksheet.mergeCells('B11:D11');

// 'الرقم'
worksheet.mergeCells('E11:E12'); // Pour 'المؤسسات'
worksheet.mergeCells('A11:A12'); // Pour 'المؤسسات'

worksheet.mergeCells('F11:F12'); // Pour 'الرقم'

  
    // Ajouter les titres centrés avec un style personnalisé
    const titleRow1 = worksheet.getRow(1);
    titleRow1.getCell(1).value = 'الجمهورية الجزائرية الديمقراطية الشعبية';
    titleRow1.getCell(1).font = { size: 16, bold: true };
    titleRow1.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    const titleRow2 = worksheet.getRow(2);
    titleRow2.getCell(1).value = 'وزارة التربية الوطنية';
    titleRow2.getCell(1).font = { size: 16, bold: true };
  
  
    // Ajouter les en-têtes
  
    const titleRow3 = worksheet.getRow(3);
    titleRow3.getCell(1).value = ' ';
    titleRow3.getCell(1).font = { size: 12, bold: true };
    titleRow3.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
  
   
    const titleRow4 = worksheet.getRow(4);
    titleRow4.getCell(1).value = 'مديرية التربية لولاية بجاية';
    titleRow4.getCell(1).font = { size: 12, bold: true };
    titleRow4.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
  
  
    const titleRow5 = worksheet.getRow(5);
    titleRow5.getCell(1).value = 'مصلحة المالية و الوسائل';
    titleRow5.getCell(1).font = { size: 12, bold: true };
    titleRow5.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
  
    const titleRow6 = worksheet.getRow(6);
    titleRow6.getCell(1).value = 'مكتب مراقبة التسيير المالي للمؤسسات';
    titleRow6.getCell(1).font = { size: 12, bold: true };
    titleRow6.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
  
    
    const titleRow7 = worksheet.getRow(7);
    titleRow7.getCell(1).value = ' ';
    titleRow7.getCell(1).font = { size: 12, bold: true };
    titleRow7.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
  
   
    const titleRow8 = worksheet.getRow(8);
    titleRow8.getCell(1).value = ' ';
    titleRow8.getCell(1).font = { size: 12, bold: true };
    titleRow8.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
  // Créez une instance de l'objet Date
  var dateDuJour = new Date();
  
  // Obtenez la date du jour sous forme de chaîne de caractères
  var dateEnChaine = dateDuJour.toDateString();
  
    var jour = dateDuJour.getDate();
    var mois = dateDuJour.getMonth() + 1; // Les mois commencent à 0, donc ajoutez 1
    var annee = dateDuJour.getFullYear();
    
    // Formatez la date
    var dateFormatee = jour + '/' + mois + '/' + annee;
    
    const titleRow9 = worksheet.getRow(9);
    titleRow9.getCell(1).value = 'الحوصلة الوالائية لأرصيدة الحسابات خارج الميزانية الموقوفة إلى غاية  :'+ dateFormatee;
    titleRow9.getCell(1).font = { size: 12, bold: true };
    titleRow9.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
  
    const titleRow10 = worksheet.getRow(10);
    titleRow10.getCell(1).value = '';
    titleRow10.getCell(1).font = { size: 15, bold: true };
    titleRow10.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
  
   



    worksheet.getCell('F11').value='الرقم';
    worksheet.getCell('F11').font = {size:12};
    worksheet.getCell('F11').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
    worksheet.getCell('F11').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    worksheet.getCell('E11').value='المؤسسات';
    worksheet.getCell('E11').font = {size:12};
    worksheet.getCell('E11').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
    worksheet.getCell('E11').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  
  
    worksheet.getCell('A9').value = 'الحوصلة الوالائية لأرصيدة الحسابات خارج الميزانية الموقوفة إلى غاية  :'+ dateFormatee;
    worksheet.getCell('A9').font = {size:12};
    worksheet.getCell('A9').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
    worksheet.getCell('A9').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  
    worksheet.getCell('B11').value=' الرصيد إلى غاية :'+ dateFormatee;
    worksheet.getCell('B11').font = {size:12};
    worksheet.getCell('B11').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
    worksheet.getCell('B11').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  
    
    worksheet.getCell('B12').value='حساب 513';
    worksheet.getCell('B12').font = {size:12};
    worksheet.getCell('B12').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
    worksheet.getCell('B12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  
  
 
    worksheet.getCell('C12').value='حساب 512';
    worksheet.getCell('C12').font = {size:12};
    worksheet.getCell('C12').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
    worksheet.getCell('C12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  
  
 
    worksheet.getCell('D12').value='حساب 511';
    worksheet.getCell('D12').font = {size:12};
    worksheet.getCell('D12').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
    worksheet.getCell('D12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  
  

    worksheet.getCell('A11').value='مجموع الفوائض الخاصة بالمصالح خارج الميزانية';
    worksheet.getCell('A11').font = {size:12};
    worksheet.getCell('A11').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
    worksheet.getCell('A11').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  
  
  
  

  



    // Ajouter les données
    worksheet.addRows(data);
  
    // Centrer la colonne 'الرقم'
    worksheet.getColumn(6).alignment = { horizontal: 'center' };
  
    // Ajuster la taille de la colonne 'المؤسسات' (colonne 'E')
    worksheet.getColumn('E').width = 30;
  
    worksheet.getColumn('A').width = 20;
  
    // Ajouter une bordure à la colonne 'F' (colonne 'الرقم')
    const columnF = worksheet.getColumn('F');
    columnF.eachCell((cell) => {
      cell.border = { bottom: { style: 'thin' },left: { style: 'thin' },right: { style: 'thin' },top: { style: 'thin' } }; // Bordure basse uniquement
    });  
    
    
    const columnE = worksheet.getColumn('E');
    columnE.eachCell((cell) => {
      cell.border = { bottom: { style: 'thin' },left: { style: 'thin' },right: { style: 'thin' },top: { style: 'thin' } }; // Bordure basse uniquement
    });
  
  
    const columnD = worksheet.getColumn('D');
    columnD.eachCell((cell) => {
      cell.border = { bottom: { style: 'thin' },left: { style: 'thin' },right: { style: 'thin' },top: { style: 'thin' } }; // Bordure basse uniquement
    });
  
    const columnC = worksheet.getColumn('C');
    columnC.eachCell((cell) => {
      cell.border = { bottom: { style: 'thin' },left: { style: 'thin' },right: { style: 'thin' },top: { style: 'thin' } }; // Bordure basse uniquement
    });
    
  
    const columnB = worksheet.getColumn('B');
    columnB.eachCell((cell) => {
      cell.border = { bottom: { style: 'thin' },left: { style: 'thin' },right: { style: 'thin' },top: { style: 'thin' } }; // Bordure basse uniquement
    });
  
    const columnA = worksheet.getColumn('A');
    columnA.eachCell((cell) => {
      cell.border = { bottom: { style: 'thin' },left: { style: 'thin' },right: { style: 'thin' },top: { style: 'thin' } }; // Bordure basse uniquement
    });
    
    worksheet.getCell('A1').border = {};
    worksheet.getCell('A2').border = {};
    worksheet.getCell('A3').border = {};
    worksheet.getCell('A4').border = {};
    worksheet.getCell('A5').border = {};
    worksheet.getCell('A6').border = {};
    worksheet.getCell('A7').border = {};
    worksheet.getCell('A8').border = {};
    worksheet.getCell('A10').border = {};
  
  
  
  
  
  
    worksheet.getCell('A3').alignment = { horizontal: 'right' };
    worksheet.getCell('A4').alignment = { horizontal: 'right' };
    worksheet.getCell('A5').alignment = { horizontal: 'right' };
    worksheet.getCell('A6').alignment = { horizontal: 'right' };
  
  
  
  
  
    // Générer le fichier Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Sold_annuel_arreté_'+ dateFormatee+'.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const numberFormat = (value) =>
  value.toLocaleString('fr-FR', {
    useGrouping: true,
    minimumFractionDigits: 2, // Indique que vous voulez toujours afficher au moins 2 décimales
    maximumFractionDigits: 2, // Vous pouvez ajuster le nombre de décimales ici
  });


  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };


  const fetchDatabs = async () => {
    try {
      axios.get(`${server}/statutSuiviA`).then((res) => {
        const foundAbs = res.data.find((sta) => sta.suiviA == 0);
        if (foundAbs) {
          setAbs(1); // Update the 'abs' state with the correct value
          console.log(foundAbs);
        } else {
          setAbs(0); // If not found, set 'abs' to 0 (default value)
          console.log(foundAbs);
        }
        setSat(res.data);
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  


    
  const handleLock = () => {
    axios.post(`${server}/statut_suiviA`, { abs })
      .then(response => {
        console.log("Données mises à jour avec succès !");
        fetchDatabs();
        launch_toast();
        console.log(abs);
      })
      .catch(error => {
        console.error("Erreur lors de la mise à jour des données :", error);
      });
  };
  const handleReset = () => {
    axios.post(`${server}/Reset_suiviA`)
      .then(response => {
        console.log("Données reset avec succès !");
        handleClose();
        launch_toast();
      })
      .catch(error => {
        console.error("Erreur lors de la mise à jour des données :", error);
      });
  };
  useEffect(() => {
    fetchDatabs();
  }, []);
    return (
      <>

      
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Attention</Modal.Title>
        </Modal.Header>
        <Modal.Body>êtes vous sûr de vouloir reinitialiser le sold annul arreté </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleReset}>
       Oui
          </Button>
        </Modal.Footer>
      </Modal>
      
      {showPopup && (
        <div className="popup">
          <div className="popup-content" style={{height:'125px'}}>
          <h2 style={{marginTop:'-1px',marginLeft:'10px'}}>Attention</h2> 
          <p style={{marginLeft:'10px'}}>êtes vous sûr de vouloir reinitialiser le sold annul arreté </p>
         
          <hr/>
           <div style={{float:'right', marginRight:'10px'}}>
          <button className="button-7-2" onClick={handleReset} style={{height:'30px', marginRight:'10px'}}>oui</button>
          <button className="button-6-2" onClick={closePopup} style={{height:'30px'}}>Annuler</button>
          </div>
          </div>
          </div>
          )}



<Card style={{marginTop:'20px'}}>   
      <Card.Header>
        
      <div>
      <div id="toast"><div id="img"><FontAwesomeIcon icon={faCircleCheck} /></div><div id="desc">Modifier avec succès </div></div>
        <div  style={{display:'flex' ,alignItems:'center'}}>
        <img style={{ width: '50px', height: '50px', marginRight: '20px' }} src={icon} alt="Inbox" />
          <p style={{ fontSize: '20px', fontWeight: '500', marginRight: '20px' }}>Sold annul arreté</p>
          {infos.length > 0 && infos[0].role === "Chef de Service" ? (
            <>
                      <Button variant="primary" onClick={exportToExcel} style={{ marginLeft: '10px',marginRight:'10px' }}>Télécharger</Button>
                      <Button variant="primary" onClick={handleLock} style={{marginRight:'20px'}}>
       { statut.map((stat) => {
        return stat.suiviA == '0' ? ( <>Bloquer</>) : (<>Débloquer</>) })}
        </Button>
<Button variant="secondary" onClick={handleShow}>Réinitialiser</Button>
            </>
          ):(<></>)}

        </div>


        
      </div>
      


      </Card.Header>

      </Card>   
      <div style={{marginTop:'10px'}}>
        {statut.map((stat)=>[
              stat.suiviA =='0' ? (<></>):(        
              <Alert variant='danger'>
            Le sold annul arreté est Bloqués
              </Alert>)
            ])}  
          
        </div>
        
      <div style={{background:'#fff',width:'100%',boxShadow:'rgba(0, 0, 0, 0.07) 0 1px 3px 0',borderRadius:'5px',height:'45vh',marginTop:"20px"}}>


  {
    load ? (<><div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'55vh'}}>
    <svg className="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
     <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
    </div></>) : (<>
    
    {
    infos.length > 0 && infos[0].role === "Chef de Service" ? (
      <>
      
      <div style={{direction:'rtl',height: '45vh',overflow:'auto'}}>

<Table striped bordered hover>
      <thead >
      <tr>
            <th>الرقم</th>
            <th>المؤسسات</th>
            <th>حساب 511</th>
            <th>حساب 512</th>
            <th>حساب 513</th>
            <th>المجموع</th>
          </tr>
      </thead>

      <tbody style={{height:'500px',overflow:'auto'}}>
      {Liste.map((list) => (
     <tr>
     <td > {list.CODETAB}</td>
     <td> {list.LIBETABA}</td>
     <td>{numberFormat(list.COMPTE511)}</td>
     <td>{numberFormat(list.COMPTE512)}</td>
     <td>{numberFormat(list.COMPTE513)}</td>
     <td>{numberFormat(list.TOT)}</td>

   </tr>

  ))}
      </tbody>
    </Table>
    
    </div>
      </>
    ) :(
   <>


<Card>

      <Card.Header as="h5">Sold annul arreté</Card.Header>
      <Card.Body>
        <Card.Title></Card.Title>
        <Card.Text>
        <Form style={{direction:'rtl'}}  onSubmit={handleSubmit} >
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label> حساب 511</Form.Label>
          <Form.Control
            type="text"
            name="compte511"
            value={values.compte511}
            onChange={handleChange}
            style={{marginTop:'20px', fontWeight:'700'}}
            disabled={statut.some(stat => stat.suiviA == '1')}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label> حساب 512</Form.Label>
          <Form.Control
            type="text"
            name="compte512"
            value={values.compte512}
            onChange={handleChange}
            style={{marginTop:'20px', fontWeight:'700'}}
            disabled={statut.some(stat => stat.suiviA == '1')}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label> حساب 513</Form.Label>
          <Form.Control
            type="text"
            name="compte513"
            value={values.compte513}
            onChange={handleChange}
            style={{marginTop:'20px', fontWeight:'700'}}
            disabled={statut.some(stat => stat.suiviA == '1')}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="validationCustom03">
          <Form.Label>المجموع</Form.Label>
          <Form.Control type="text"
        name="total"
        value={values.total}
        onChange={handleChange}
        readOnly
        disabled
        style={{pointerEvents:'none'}}/>
        </Form.Group>
      </Row>
      <Button variant="primary" type="submit"  disabled={statut.some(stat => stat.suiviA == '1')}>Valider</Button>
    </Form>
        </Card.Text>
    
      </Card.Body>
    </Card>





    </>
    )
  }
    </>)
  }

  </div>
       
  </>
  
    );
};

export default SuiviA;
