import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx'
import icon from '../../asset/Caisse.png';
import ExcelJS from 'exceljs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CDBTable, CDBTableHeader, CDBTableBody, CDBContainer } from 'cdbreact';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBookOpen, faUsers,faCircleCheck,faFilter,faTable,faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
function Suivi(props) {
  const server = props.server;
  const [infos, setInfo] = useState([]);
  const [Liste , setListe] =useState([]);
  const [abs, setAbs] = useState(props.state);
  const [statut,setSat] =useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [load,setLoad] = useState(true);
  const [show, setShow] = useState(false);

  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function launch_toast() {
    var x = document.getElementById("toast")
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", "");}, 5000);
  }
  
  const [values, setValues] = useState({
    balance: '',
    cashBalance: '',
    total: '', // Le total sera calculé automatiquement
    note: '',
  });



  const numberFormat = (value) =>
  value.toLocaleString('fr-FR', {
    useGrouping: true,
    minimumFractionDigits: 2, // Indique que vous voulez toujours afficher au moins 2 décimales
    maximumFractionDigits: 2, // Vous pouvez ajuster le nombre de décimales ici
  });


  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const fetchDatabs = async () => {
    try {
      axios.get(`${server}/statutSuivi`).then((res) => {
        const foundAbs = res.data.find((sta) => sta.suivi == 0);
        if (foundAbs) {
          setAbs(1); // Update the 'abs' state with the correct value
          console.log(foundAbs);
        } else {
          setAbs(0); // If not found, set 'abs' to 0 (default value)
          console.log(foundAbs);
        }
        setSat(res.data);
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  
  const handleLock = () => {
    axios.post(`${server}/statut_suivi`, { abs })
      .then(response => {
        console.log("Données mises à jour avec succès !");
        fetchDatabs();
        launch_toast();
        console.log(abs);
      })
      .catch(error => {
        console.error("Erreur lors de la mise à jour des données :", error);
      });
  };
  
  useEffect(() => {
  
    axios.get(`${server}/info_u`).then((res) => {
      setInfo(res.data);
      setLoad(false);
      });
    }, []);
  useEffect(() => {
    // Récupérez les données existantes de la table SuiviStat depuis votre serveur Node.js
    axios.get(`${server}/getSuiviStat`).then((response) => {
      const data = response.data[0]; // Supposons que vous récupérez une seule ligne de données
      if (data) {
        setValues({
          balance: data.SOLDC,
          cashBalance: data.SOLD2,
          total: data.SOLDC + data.SOLD2, // Calculez le total ici
          note: data.NOTE,
        });
      }
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  useEffect(() => {
    calculateTotal();
  }, [values.balance, values.cashBalance]);
  
 

  
  useEffect(() => {
    calculateTotal(); // Appelez calculateTotal au chargement initial
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Envoyez les données mises à jour au serveur Node.js pour les enregistrer dans la base de données
    await axios.post(`${server}/updateSuiviStat`, values);
    alert('Données mises à jour avec succès !');
  };


  const handleReset = () => {
    axios.post(`${server}/Reset_suivi`)
      .then(response => {
        console.log("Données reset avec succès !");
        handleClose();
        launch_toast();
      })
      .catch(error => {
        console.error("Erreur lors de la mise à jour des données :", error);
      });
  };

  
  useEffect(() => {
    if (infos.length > 0 && infos[0].role === "Chef de Service") {
      axios.get(`${server}/ListeStat`).then((res) => {
        setListe(res.data);
      });
    }
  }, [infos]);
  
   


  const calculateTotal = () => {
    const { balance, cashBalance } = values;
  
    // Assurez-vous que balance et cashBalance sont des nombres
    const balanceValue = parseFloat(balance) || 0;
    const cashBalanceValue = parseFloat(cashBalance) || 0;
  
    const total = balanceValue + cashBalanceValue;
  
    setValues((prevValues) => ({
      ...prevValues,
      total: total.toString(),
    }));
  };



const exportToExcel = async () => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Liste');

  // Define the title and data to export (dans votre cas, Liste) 
  const title = [
    ['الجمهورية الجزائرية الديمقراطية الشعبية'],
    ['وزارة التربية الوطنية'],
    ['مديرية التربية لولاية بجاية'],

  ];
  const data = Liste.map((list) => [
    list.NOTE,
    list.TOT,
    list.SOLD2,
    list.SOLDC,
    list.LIBETABA,
    list.CODETAB,
  ]);

  // Fusionner et centrer les cellules pour le titre
  worksheet.mergeCells('A1:F1');
  worksheet.mergeCells('A2:F2');
  worksheet.mergeCells('A3:F3');
  worksheet.mergeCells('A4:F4');
  worksheet.mergeCells('A5:F5');
  worksheet.mergeCells('A6:F6');
  worksheet.mergeCells('A7:F7');
  worksheet.mergeCells('A8:F8');
  worksheet.mergeCells('A9:F9');
  worksheet.mergeCells('A10:F10');



  // Ajouter les titres centrés avec un style personnalisé
  const titleRow1 = worksheet.getRow(1);
  titleRow1.getCell(1).value = 'الجمهورية الجزائرية الديمقراطية الشعبية';
  titleRow1.getCell(1).font = { size: 16, bold: true };
  titleRow1.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

  const titleRow2 = worksheet.getRow(2);
  titleRow2.getCell(1).value = 'وزارة التربية الوطنية';
  titleRow2.getCell(1).font = { size: 16, bold: true };


  // Ajouter les en-têtes

  const titleRow3 = worksheet.getRow(3);
  titleRow3.getCell(1).value = ' ';
  titleRow3.getCell(1).font = { size: 12, bold: true };
  titleRow3.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

 
  const titleRow4 = worksheet.getRow(4);
  titleRow4.getCell(1).value = 'مديرية التربية لولاية بجاية';
  titleRow4.getCell(1).font = { size: 12, bold: true };
  titleRow4.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };


  const titleRow5 = worksheet.getRow(5);
  titleRow5.getCell(1).value = 'مصلحة المالية و الوسائل';
  titleRow5.getCell(1).font = { size: 12, bold: true };
  titleRow5.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

  const titleRow6 = worksheet.getRow(6);
  titleRow6.getCell(1).value = 'مكتب مراقبة التسيير المالي للمؤسسات';
  titleRow6.getCell(1).font = { size: 12, bold: true };
  titleRow6.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

  
  const titleRow7 = worksheet.getRow(7);
  titleRow7.getCell(1).value = ' ';
  titleRow7.getCell(1).font = { size: 12, bold: true };
  titleRow7.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

 
  const titleRow8 = worksheet.getRow(8);
  titleRow8.getCell(1).value = ' ';
  titleRow8.getCell(1).font = { size: 12, bold: true };
  titleRow8.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
// Créez une instance de l'objet Date
var dateDuJour = new Date();

// Obtenez la date du jour sous forme de chaîne de caractères
var dateEnChaine = dateDuJour.toDateString();

  var jour = dateDuJour.getDate();
  var mois = dateDuJour.getMonth() + 1; // Les mois commencent à 0, donc ajoutez 1
  var annee = dateDuJour.getFullYear();
  
  // Formatez la date
  var dateFormatee = jour + '/' + mois + '/' + annee;
  
  const titleRow9 = worksheet.getRow(9);
  titleRow9.getCell(1).value = 'وضعية الصندوق إلى غاية :'+ dateFormatee;
  titleRow9.getCell(1).font = { size: 15, bold: true };
  titleRow9.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

  const titleRow10 = worksheet.getRow(10);
  titleRow10.getCell(1).value = '';
  titleRow10.getCell(1).font = { size: 15, bold: true };
  titleRow10.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

 
 

 
 
  const headerRow = worksheet.addRow(['الملاحظات', 'المجموع', 'الرصيد نقدا', 'رصيد الخزينة', 'المؤسسات', 'الرقم']);

  // Ajouter les données
  worksheet.addRows(data);

  // Centrer la colonne 'الرقم'
  worksheet.getColumn(6).alignment = { horizontal: 'center' };

  // Ajuster la taille de la colonne 'المؤسسات' (colonne 'E')
  worksheet.getColumn('E').width = 30;
  

  worksheet.getColumn('A').width = 20;

  // Ajouter une bordure à la colonne 'F' (colonne 'الرقم')
  const columnF = worksheet.getColumn('F');
  columnF.eachCell((cell) => {
    cell.border = { bottom: { style: 'thin' },left: { style: 'thin' },right: { style: 'thin' },top: { style: 'thin' } }; // Bordure basse uniquement
  });  
  
  
  const columnE = worksheet.getColumn('E');
  
  columnE.eachCell((cell) => {
    cell.border = { bottom: { style: 'thin' },left: { style: 'thin' },right: { style: 'thin' },top: { style: 'thin' } }; // Bordure basse uniquement
  });


  const columnD = worksheet.getColumn('D');
  columnD.eachCell((cell) => {
    cell.border = { bottom: { style: 'thin' },left: { style: 'thin' },right: { style: 'thin' },top: { style: 'thin' } }; // Bordure basse uniquement
  });

  const columnC = worksheet.getColumn('C');
  columnC.eachCell((cell) => {
    cell.border = { bottom: { style: 'thin' },left: { style: 'thin' },right: { style: 'thin' },top: { style: 'thin' } }; // Bordure basse uniquement
  });
  

  const columnB = worksheet.getColumn('B');
  columnB.eachCell((cell) => {
    cell.border = { bottom: { style: 'thin' },left: { style: 'thin' },right: { style: 'thin' },top: { style: 'thin' } }; // Bordure basse uniquement
  });

  const columnA = worksheet.getColumn('A');
  columnA.eachCell((cell) => {
    cell.border = { bottom: { style: 'thin' },left: { style: 'thin' },right: { style: 'thin' },top: { style: 'thin' } }; // Bordure basse uniquement
  });
  
  worksheet.getCell('A1').border = {};
  worksheet.getCell('A2').border = {};
  worksheet.getCell('A3').border = {};
  worksheet.getCell('A4').border = {};
  worksheet.getCell('A5').border = {};
  worksheet.getCell('A6').border = {};
  worksheet.getCell('A7').border = {};
  worksheet.getCell('A8').border = {};

  worksheet.getCell('A10').border = {};






  worksheet.getCell('A3').alignment = { horizontal: 'right' };
  worksheet.getCell('A4').alignment = { horizontal: 'right' };
  worksheet.getCell('A5').alignment = { horizontal: 'right' };
  worksheet.getCell('A6').alignment = { horizontal: 'right' };





  // Générer le fichier Excel
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;


  var today = new Date();

// Formater la date selon vos préférences
var formattedDate = today.toLocaleDateString('fr-FR'); // Vous pouvez ajuster le paramètre de localisation selon votre besoin

// Créer le nom du fichier en ajoutant la date formatée


  a.download = 'Suivi_de_caisse_' + formattedDate + '.xlsx';
  a.click();
  window.URL.revokeObjectURL(url);
};


useEffect(() => {
  fetchDatabs();
}, []);


    return (
        <>
  

<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Attention</Modal.Title>
        </Modal.Header>
        <Modal.Body>êtes vous sûr de vouloir reinitialiser le suivi de caisse </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleReset}>
       Oui
          </Button>
        </Modal.Footer>
      </Modal>



 <Card style={{marginTop:'20px'}}>   
      <Card.Header>
        
        <div style={{display:'flex' ,alignItems:'center'}}>

<img style={{ width: '50px', height: '50px', marginRight: '20px' }} src={icon} alt="Inbox" />
 
  <p style={{ fontSize: '20px', fontWeight: '500', marginRight: '20px'}}>Suivi De caisse</p>
  {infos.length > 0 && infos[0].role === "Chef de Service" ? (
    <>
<div style={{display:'flex',float:'right'}}> 
<Button variant="primary" onClick={exportToExcel} style={{ marginLeft: '10px',marginRight:'10px' }}>Télécharger</Button>
              <Button variant="primary" onClick={handleLock} style={{marginRight:'20px'}}>
{ statut.map((stat) => {
return stat.suivi == '0' ? ( <>Bloquer</>) : (<>Débloquer</>) })}
</Button>
<Button variant="secondary" onClick={handleShow}>Réinitialiser</Button>
        </div>
        
    </>
  ):(<></>)}

</div>

</Card.Header>
    </Card>

    {statut.map((stat)=>[
      stat.suivi =='0' ? (<></>):(        
      <Alert variant='danger' style={{marginLeft:'20px',marginTop:'10px'}}>
    Le suivi de caisse est Bloqués
      </Alert>)
    ])}  



      
      <div style={{background:'#fff',width:'100%',boxShadow:'rgba(0, 0, 0, 0.07) 0 1px 3px 0',borderRadius:'5px',height:'45vh',marginTop:"20px"}}>

{
  load ? (<><div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'55vh'}}>
  <svg className="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
   <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
  </svg>
  </div></>) : (<>
  
  
  
    {
    infos.length > 0 && infos[0].role === "Chef de Service" ? (
      <>
      
<div style={{direction:'rtl',height: '50vh',overflow:'auto'}}>

<Table striped bordered hover>
      <thead >
      <tr>
            <th>الرقم</th>
            <th>المؤسسات</th>
            <th>رصيد الخزينة</th>
            <th>الرصيد نقدا</th>
            <th>المجموع</th>
            <th>الملاحظات</th>
          </tr>
      </thead>

      <tbody>
      {Liste.map((list) => (
     <tr>
     <td > {list.CODETAB}</td>
     <td> {list.LIBETABA}</td>
     <td>{numberFormat(list.SOLDC)}</td>
     <td>{numberFormat(list.SOLD2)}</td>
     <td>{numberFormat(list.TOT)}</td>
     <td>{list.NOTE} </td>

   </tr>

  ))}
      </tbody>
    </Table>

    </div>
      </>
    ) :(
   <>

<Card>

      <Card.Header as="h5">Suivi De caisse</Card.Header>
      <Card.Body>
        <Card.Title></Card.Title>
        <Card.Text>
        <Form style={{direction:'rtl'}}  onSubmit={handleSubmit} >
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>رصيد الخزينة</Form.Label>
          <Form.Control
            type="text"
            name="balance"
            value={values.balance}
            onChange={handleChange}
            disabled={statut.some(stat => stat.suivi == '1')}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>الرصيد نقدا</Form.Label>
          <Form.Control
            type="text"
            name="cashBalance"
            value={values.cashBalance}
            onChange={handleChange}
            disabled={statut.some(stat => stat.suivi == '1')}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="validationCustom03">
          <Form.Label>المجموع</Form.Label>
          <Form.Control type="text"
                      name="total"
                      value={values.total}
                      readOnly
          disabled
            style={{pointerEvents:'none'}} />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="validationCustom03">
          <Form.Label>الملاحظة</Form.Label>
          <Form.Control type="text" placeholder="الملاحظة" name="note"
  value={values.note}
  onChange={handleChange}
  disabled={statut.some(stat => stat.suivi == '1')} />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Button variant="primary" type="submit"  disabled={statut.some(stat => stat.suivi == '1')}>Valider</Button>
    </Form>
        </Card.Text>
    
      </Card.Body>
    </Card>
    </>
    )
  }
  
  </>)


}


  </div>
       
  </>
  
    );
};

export default Suivi;
