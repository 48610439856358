import React, { useState, useEffect,useMemo } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx'
import icon from '../../asset/Caisse.png';
import FileSaver from 'file-saver';
import Card from 'react-bootstrap/Card';
import ExcelJS from 'exceljs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { saveAs } from 'file-saver';
import { faBook, faBookOpen, faUsers,faCircleCheck,faFilter,faTable,faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
function SuiviCant(props) {
  const server = props.server;
  const [infos, setInfo] = useState([]);
  const [infoscant, setInfocant] = useState([]);
  const [filterDate, setFilterDate] = useState(getCurrentDate()); // État pour la date de filtrage


  const [Liste , setListe] =useState([]);
  const [etab, setEtab] = useState();
  const [statut,setSat] =useState([]);
  const [load,setLoad] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [communess,setCommune] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [values, setValues] = useState({
    compte511: '',
  });

  
function launch_toast() {
  var x = document.getElementById("toast")
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", "");}, 5000);
}





useEffect(() => {
  axios.get(`${server}/info_u`).then((res) => {
    setInfo(res.data);
    setLoad(false);
    setCommune(res.data[0].commune);
  });
}, []);




useEffect(() => {
  if (infos.length > 0 && infos[0].type === "PROGRAMATION") {
    axios.get(`${server}/ListeCantine`).then((res) => {
      // Set 'liste' with the response data
      setListe(res.data);
    });
  }
}, [infos]);

  



  
useEffect(() => {
  axios.get(`${server}/etab`).then((res) => {
    setEtab(res.data);
  });
}, []);




useEffect(() => {
  axios.get(`${server}/info_cant`).then((res) => {
    setInfocant(res.data);
    const data = res.data[0]; 
    if (data) {
      setValues({
        compte511: data.NeleveCant,
      });
    }
  });
}, []);





  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  
  const handleSubmit = async (e) => {
    if (infoscant.length === 0) {

      e.preventDefault();
  
      // Prepare the data to send in the request body
      const requestData = {
      values: values, communes: communess

      };
  
      try {
  
        await axios.post(`${server}/cantine`, requestData);
        alert('Données mises à jour avec succès !');
      } catch (error) {
        console.error('Error updating data:', error);
        // Handle error as needed
      }
    } else {
      console.log(infoscant.length);
      e.preventDefault();

      try {
        await axios.post(`${server}/updatecantine`, values);
        alert('Données mises à jour avec succès !');
      } catch (error) {
        console.error('Error updating data:', error);
        // Handle error as needed
      }
    }
  };
  


  const numberFormat = (value) =>
  value.toLocaleString('fr-FR', {
    useGrouping: true,
    minimumFractionDigits: 2, // Indique que vous voulez toujours afficher au moins 2 décimales
    maximumFractionDigits: 2, // Vous pouvez ajuster le nombre de décimales ici
  });


  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };





  function getTotalNeleveByCommune(etab, communeName) {
    let totalNeleve = 0;
  
    etab.forEach((item) => {
      if (item.commune === communeName) {
        totalNeleve += item.Neleve;
       
      }
    });
  return totalNeleve;
  }


  

  function getNombreOuiDansCantine(commune, etab) {
    const cantineCounts = {};
  
    etab.forEach((item) => {
      const communeItem = item.commune;
      const cantine = item.cantine;
  
      if (communeItem && cantine === "oui") {
        if (cantineCounts[communeItem]) {
          cantineCounts[communeItem]++;
        } else {
          cantineCounts[communeItem] = 1;
        }
      }
    });
  
    const nombreOui = cantineCounts[commune] || 0;
    return nombreOui;
  }





  function getNombreOuiDansCantinestat(commune, etab) {
    const cantineCounts = {};
  
    etab.forEach((item) => {
      const communeItem = item.commune;
      const cantine = item.statuscant;
  
      if (communeItem && cantine === "ouverte") {
        if (cantineCounts[communeItem]) {
          cantineCounts[communeItem]++;
        } else {
          cantineCounts[communeItem] = 1;
        }
      }
    });
  
    const nombreOui = cantineCounts[commune] || 0;
    return nombreOui;
  }


  function getNombreOuiDansRepaschaud(commune, etab) {
    const cantineCounts = {};
  
    etab.forEach((item) => {
      const communeItem = item.commune;
      const cantine = item.typer;
  
      if (communeItem && cantine === "chaud") {
        if (cantineCounts[communeItem]) {
          cantineCounts[communeItem]++;
        } else {
          cantineCounts[communeItem] = 1;
        }
      }
    });
  
    const nombreOui = cantineCounts[commune] || 0;
    return nombreOui;
  }




  function getNombreOuiDansRepasfroid(commune, etab) {
    const cantineCounts = {};
  
    etab.forEach((item) => {
      const communeItem = item.commune;
      const cantine = item.typer;
  
      if (communeItem && cantine === "froid") {
        if (cantineCounts[communeItem]) {
          cantineCounts[communeItem]++;
        } else {
          cantineCounts[communeItem] = 1;
        }
      }
    });
  
    const nombreOui = cantineCounts[commune] || 0;
    return nombreOui;
  }



  function getNombreDeCantineFerme(commune, etab) {
    const cantineCounts = {};
  
    etab.forEach((item) => {
      const communeItem = item.commune;
      const cantine = item.statuscant;
  
      if (communeItem && cantine === "fermee") {
        if (cantineCounts[communeItem]) {
          cantineCounts[communeItem]++;
        } else {
          cantineCounts[communeItem] = 1;
        }
      }
    });
  
    const nombreOui = cantineCounts[commune] || 0;
    return nombreOui;
  }






  function getRaison1(commune, etab) {
    const cantineCounts = {};
  
    etab.forEach((item) => {
      const communeItem = item.commune;
      const cantine = item.statuscant;
      const raison = item.R_salle;

  
      if (communeItem && cantine === "fermee" && raison ==='1' ) {
        if (cantineCounts[communeItem]) {
          cantineCounts[communeItem]++;
        } else {
          cantineCounts[communeItem] = 1;
        }
      }
    });
  
    const nombreOui = cantineCounts[commune] || 0;
    return nombreOui;
  }


  function getRaison2(commune, etab) {
    const cantineCounts = {};
  
    etab.forEach((item) => {
      const communeItem = item.commune;
      const cantine = item.statuscant;
      const raison = item.R_matériel;

  
      if (communeItem && cantine === "fermee" && raison ==='1' ) {
        if (cantineCounts[communeItem]) {
          cantineCounts[communeItem]++;
        } else {
          cantineCounts[communeItem] = 1;
        }
      }
    });
  
    const nombreOui = cantineCounts[commune] || 0;
    return nombreOui;
  }

  function getRaison3(commune, etab) {
    const cantineCounts = {};
  
    etab.forEach((item) => {
      const communeItem = item.commune;
      const cantine = item.statuscant;
      const raison = item.R_fourniture;

  
      if (communeItem && cantine === "fermee" && raison ==='1' ) {
        if (cantineCounts[communeItem]) {
          cantineCounts[communeItem]++;
        } else {
          cantineCounts[communeItem] = 1;
        }
      }
    });
  
    const nombreOui = cantineCounts[commune] || 0;
    return nombreOui;
  }


  function getRaison4(commune, etab) {
    const cantineCounts = {};
  
    etab.forEach((item) => {
      const communeItem = item.commune;
      const cantine = item.statuscant;
      const raison = item.R_assistants;

  
      if (communeItem && cantine === "fermee" && raison ==='1' ) {
        if (cantineCounts[communeItem]) {
          cantineCounts[communeItem]++;
        } else {
          cantineCounts[communeItem] = 1;
        }
      }
    });
  
    const nombreOui = cantineCounts[commune] || 0;
    return nombreOui;
  }


  function getRaison5(commune, etab) {
    const cantineCounts = {};
  
    etab.forEach((item) => {
      const communeItem = item.commune;
      const cantine = item.statuscant;
      const raison = item.autre;

  
      if (communeItem && cantine === "fermee" && raison ==='1' ) {
        if (cantineCounts[communeItem]) {
          cantineCounts[communeItem]++;
        } else {
          cantineCounts[communeItem] = 1;
        }
      }
    });
  
    const nombreOui = cantineCounts[commune] || 0;
    return nombreOui;
  }





  function getNombreEtabCommune(commune, etab) {
    const cantineCounts = {};
  
    etab.forEach((item) => {
      const communeItem = item.commune;

  
      if (communeItem ) {
        if (cantineCounts[communeItem]) {
          cantineCounts[communeItem]++;
        } else {
          cantineCounts[communeItem] = 1;
        }
      }
    });
  
    const nombreOui = cantineCounts[commune] || 0;
    return nombreOui;
  }

 

  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire
    const day = now.getDate().toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire
    return `${year}-${month}-${day}`;
  }
  




    const filteredData = Liste.filter(
      (item) =>
        item.commune !== null &&
        item.commune !== "" &&
        item.NeleveCantJ !== null &&
        item.date !== null
    );
  
    const groupedData = filteredData.reduce((accumulator, item) => {
      const formattedDate = item.date ? item.date.split("T")[0] : "";
      const communeName = item.commune;
  
      if (!accumulator[formattedDate]) {
        accumulator[formattedDate] = {};
      }
  
      if (!accumulator[formattedDate][communeName]) {
        accumulator[formattedDate][communeName] = 0;
      }
  
      accumulator[formattedDate][communeName] += item.NeleveCantJ
        ? parseInt(item.NeleveCantJ)
        : 0;
  
      return accumulator;
    }, {});
      

  
    const filteredGroupedData = Object.keys(groupedData).map((formattedDate) => {
      const dataByDate = groupedData[formattedDate];
      return {
        date: formattedDate,
        data: dataByDate,
      };
    }).filter((item) => {
      if (!filterDate) {
        return true; // Si aucune date de filtrage n'est saisie, afficher toutes les données.
      }
  
      return item.date === filterDate;
    });

    





    function exportDataToExcel() {
      const dataForExport = [];
    
      // Créez un nouveau classeur (workbook) et une nouvelle feuille de calcul (worksheet)
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Données');
    
      // Ajoutez les éléments au-dessus du tableau
      worksheet.addRow(['الجمهورية الجزائرية الديمقراطية الشعبية']);
      worksheet.addRow(['ولاية بجاية']);
      worksheet.addRow(['مديرية التربية لولاية بجاية']);
      worksheet.addRow(['جدول رقم 01 يتضمن الحصيلة اليومية لمتابعة عملية التكفل بالتغذية المدرسية']);
      worksheet.addRow(['جدول رقم 01 يتضمن الحصيلة اليومية لمتابعة عملية التكفل بالتغذية المدرسية']);
      
      // Obtenir la date actuelle et formatez-la comme vous le souhaitez
      const currentDate = new Date();
      const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
      worksheet.addRow([filterDate]);
    
      // Ajoutez la ligne d'en-tête
      const headerRow = [
        'اسباب اخرى اذكرها',
        'نقص الأعوان',
        'نقص التموين',
        'نقص المعدات و التجهيزات',
        'وضعية الهياكل او القعات',
        'عدد المطعم المغلقة',
        'عدد المطاعم التي تقدم وجبات باردة',
        'عدد المطاعم التي تقدم وجبات ساخنة',
        'عدد المطعم المفتوحة',
        'العدد المستافدين من التغذية',
        'العدد الإجمالي للتلاميذ',
        'العدد الإجمالي للمطاعم',
        'العدد الإجمالي',
        'البادية',
      ];
      worksheet.addRow(headerRow);
    
      // Le reste de votre code pour remplir les données reste inchangé



      worksheet.mergeCells('A1:N1');
      worksheet.mergeCells('A2:N2');
      worksheet.mergeCells('A3:N3');
      worksheet.mergeCells('A4:N4');
      worksheet.mergeCells('A5:N5');
      worksheet.mergeCells('A6:F6');
      worksheet.mergeCells('G6:K6');
      worksheet.mergeCells('A7:E7');

      worksheet.mergeCells('M12:M6');
      worksheet.mergeCells('N12:N6');
      worksheet.mergeCells('L12:L6');
      worksheet.mergeCells('K7:K12');
      worksheet.mergeCells('J7:J12');
      worksheet.mergeCells('I7:I12');
      worksheet.mergeCells('H7:H12');
      worksheet.mergeCells('G7:G12');
      worksheet.mergeCells('F7:F12');



      worksheet.mergeCells('E8:E12');
      worksheet.mergeCells('D8:D12');
      worksheet.mergeCells('C8:C12');
      worksheet.mergeCells('B8:B12');
      worksheet.mergeCells('A8:A12');








      const titleRow1 = worksheet.getRow(1);
      titleRow1.getCell(1).value = 'الجمهورية الجزائرية الديمقراطية الشعبية';
      titleRow1.getCell(1).font = { size: 12, bold: true };
      titleRow1.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    

      const titleRow2 = worksheet.getRow(2);
      titleRow2.getCell(1).value = 'ولاية بجاية';
      titleRow2.getCell(1).font = { size: 12, bold: false };
    


      
  const titleRow4 = worksheet.getRow(3);
  titleRow4.getCell(1).value = 'مديرية التربية لولاية بجاية';
  titleRow4.getCell(1).font = { size: 12, bold: false };





  const titleRow5 = worksheet.getRow(4);
  titleRow5.getCell(1).value = 'جدول رقم 01 يتضمن الحصيلة اليومية لمتابعة عملية التكفل بالتغذية المدرسية';
  titleRow5.getCell(1).font = { size: 12, bold: false };
  titleRow5.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

  const titleRow6 = worksheet.getRow(5);
  titleRow6.getCell(1).value = filterDate;
  titleRow6.getCell(1).font = { size: 12, bold: false};
  titleRow6.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };





  const titleRow7 = worksheet.getRow(6);
  titleRow7.getCell(1).value = 'المطاعم المغلقة';
  titleRow7.getCell(1).font = { size: 12, bold: false};
  titleRow7.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  titleRow7.getCell(1).border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };


  worksheet.getCell('G6').value="المطاعم المفتوحة";
  worksheet.getCell('G6').font = {size:12};
  worksheet.getCell('G6').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('G6').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };;


  worksheet.getCell('N6').value="البلدية";
  worksheet.getCell('N6').font = {size:12};
  worksheet.getCell('N6').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true};
  worksheet.getCell('N6').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };


  worksheet.getCell('M6').value="العدد الإجمالي";
  worksheet.getCell('M6').font = {size:12};
  worksheet.getCell('M6').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true};
  worksheet.getCell('M6').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };


  worksheet.getCell('L6').value="العدد الإجمالي للمطاعم ";
  worksheet.getCell('L6').font = {size:12};
  worksheet.getCell('L6').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('L6').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };


  worksheet.getCell('K7').font = {size:12};
  worksheet.getCell('K7').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('K7').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };




  worksheet.getCell('F7').font = {size:12};
  worksheet.getCell('F7').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('F7').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };


  worksheet.getCell('A7').value="أسباب غلق المطاعم";
  worksheet.getCell('A7').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('A7').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  worksheet.getCell('A8').value='اسباب اخرى اذكرها';
  worksheet.getCell('A8').font = {size:12};
  worksheet.getCell('A8').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('A8').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };



  worksheet.getCell('J7').font = {size:12};
  worksheet.getCell('J7').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('J7').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };

  worksheet.getCell('I7').font = {size:12};
  worksheet.getCell('I7').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('I7').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };

  
  worksheet.getCell('H7').font = {size:12};
  worksheet.getCell('H7').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('H7').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };

  worksheet.getCell('D8').value= 'نقص الأعوان';
  worksheet.getCell('D8').font = {size:12};
  worksheet.getCell('D8').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('D8').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };

  worksheet.getCell('C8').value='نقص التموين';
  worksheet.getCell('C8').font = {size:12};
  worksheet.getCell('C8').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('C8').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };


  worksheet.getCell('B8').value='نقص الأعوان';
  worksheet.getCell('B8').font = {size:12};
  worksheet.getCell('B8').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('B8').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };





  worksheet.getCell('E8').value='وضعية الهياكل او القعات';
  worksheet.getCell('E8').font = {size:12};
  worksheet.getCell('E8').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('E8').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };



  worksheet.getCell('D8').value='نقص المعدات و التجهيزات';
  worksheet.getCell('D8').font = {size:12};
  worksheet.getCell('D8').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('D8').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };




  worksheet.getCell('G7').font = {size:12};
  worksheet.getCell('G7').alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true};
  worksheet.getCell('G7').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };

  worksheet.getCell('N6').alignment = {
    vertical: 'middle',  // Centre verticalement
    horizontal: 'center' // Centre horizontalement
  };

  worksheet.getCell('M6').alignment = { wrapText: true,    vertical: 'middle',  // Centre verticalement
  horizontal: 'center'  }; 



  filteredGroupedData.forEach((item) => {
    Object.keys(item.data).forEach((commune) => {
      const rowData = [
        getRaison5(commune, etab),
        getRaison4(commune, etab),
        getRaison3(commune, etab),
        getRaison2(commune, etab),
        getRaison1(commune, etab),
        getNombreDeCantineFerme(commune, etab),
        getNombreOuiDansRepasfroid(commune, etab),
        getNombreOuiDansRepaschaud(commune, etab),
        getNombreOuiDansCantinestat(commune, etab),
     
        item.data[commune],
        getTotalNeleveByCommune(etab, commune),
        
        getNombreOuiDansCantine(commune, etab),
  
        getNombreEtabCommune(commune, etab),

        commune,
      ];
  
      const row = worksheet.addRow(rowData);
      
      // Ajoutez des bordures aux cellules
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });
  });
      // Utilisez Blob pour générer le fichier Excel
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `cantine-${filterDate}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    }

    

    

 return (
      <>







<Card style={{marginTop:'20px'}}>   
      <Card.Header>
      <div style={{display:'flex'}}>
      <div id="toast"><div id="img"><FontAwesomeIcon icon={faCircleCheck} /></div><div id="desc">Modifier avec succès </div></div>
        <div className="sub-header">
        <img style={{ width: '50px', height: '50px', marginRight: '20px' }} src={icon} alt="Inbox" />
          <p style={{ fontSize: '20px', fontWeight: '500', marginRight: '20px' }}>Suivi De Canitne</p>
          { infos.length > 0 && infos[0].role === "Chef de Service" ? (
            <>

<div>
Date:
<input
  type="date"
  value={filterDate || getCurrentDate()}
  onChange={(e) => setFilterDate(e.target.value)}
  className="obj" style={{width:'150px',marginLeft:'10px'}}
/>



    </div>
    <Button className="button-6" style={{marginLeft:'20px'}} onClick={exportDataToExcel}>Exporter Excel</Button>

            </>

          ):(<></>)}
 
        </div>
        <div className="right-header">
      
        </div>
      </div>

      </Card.Header>

      </Card>   






      <div style={{background:'#fff',width:'100%',boxShadow:'rgba(0, 0, 0, 0.07) 0 1px 3px 0',borderRadius:'5px',height:'65vh',marginTop:"20px"}}>


  {
    load ? (<><div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'55vh'}}>
    <svg className="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
     <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
    </div></>) : (<>
    
    {
    infos.length > 0 && infos[0].role === "Chef de Service" ? (
      <>



{ filteredGroupedData.length== 0 ? (<><h5 style={{color:'rgba(0,0,0,0.3)' ,display:'flex', alignItems:'center',justifyContent:'center',height:'90%' }}>-vide-</h5></>):(filteredGroupedData.map((item) => (

<Table striped bordered hover style={{direction:'rtl'}}>
      <thead>
        <tr>
          <th>البادية</th>
          <th>العدد الإجمالي</th>
          <th>العدد الإجمالي للمطاعم</th>
          <th>العدد الإجمالي للتلاميذ</th>
          <th>العدد المستافدين من التغذية</th>
          <th>عدد المطعم المفتوحة</th>
          <th>عدد المطاعم التي تقدم وجبات ساخنة</th>
          <th>عدد المطاعم التي تقدم وجبات باردة</th>

        </tr>
      </thead>
      <tbody>
        
{
  Object.keys(item.data).map((commune) => (
  <tr>
  <td>{commune}</td>
  <td>{getNombreEtabCommune(commune, etab)}</td>
  <td>{getNombreOuiDansCantine(commune, etab)}</td>
  <td>{getTotalNeleveByCommune(etab, commune)}</td>
  <td>{item.data[commune]}</td>
  <td>{getNombreOuiDansCantinestat(commune, etab)}</td>
  <td>{getNombreOuiDansRepaschaud(commune, etab)}</td>
  <td>{getNombreOuiDansRepasfroid(commune, etab)}</td>

</tr>
  

  ))
  

  }

  </tbody>
  </Table>
)))


        }


      </>
    ) :(
   <>
<Card  style={{marginTop:'20px'}}>

<Card.Header as="h5" >Suivi De Canitne</Card.Header>
<Card.Body>
  <Card.Title></Card.Title>
  <Card.Text>
  <Form style={{direction:'rtl'}}  onSubmit={handleSubmit} >

<Row className="mb-3">
  <Form.Group as={Col} controlId="validationCustom03">
    <Form.Label>عدد التلميذ المستفيدين من التغذية</Form.Label>



    {infoscant.length === 0 ? (<>      <Form.Control
        type="text"
        className="obj"
        name="compte511"

        onChange={handleChange}
        style={{marginTop:'20px', fontWeight:'700'}}
      />
</>):(<>      <Form.Control
        type="text"
        className="obj"
        name="compte511"
        value={values.compte511}
        onChange={handleChange}
        style={{marginTop:'20px', fontWeight:'700'}}
      />
</>)}

  </Form.Group>
</Row>
<Button variant="primary" type="submit"  disabled={statut.some(stat => stat.suivi == '1')}>Valider</Button>
</Form>
  </Card.Text>

</Card.Body>
</Card>
    </>
    )
  }
    </>)
  }

  </div>
       
  </>
  
    );

};
export default SuiviCant;

