import {BrowserRouter , Routes ,Route ,NavLink,Outlet} from 'react-router-dom';
import '../../css/navlink.css';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
export default function NavlinkS(){

    return(
        
         <>

                  <Card>
      <Card.Header style={{display:'flex' , justifyContent:'center'}}>
        <Nav variant="pills" defaultActiveKey="#first">
          <Nav.Item>
          <NavLink to="/salary/abs" style={{textDecoration:'none'}}>
          <Nav.Link href="#first">Absence</Nav.Link>
            </NavLink>
          </Nav.Item>
          <NavLink to="/salary/note" style={{textDecoration:'none'}}>
          <Nav.Link href="#send">Note</Nav.Link>
            </NavLink>
        </Nav>
      </Card.Header>
      </Card>
      
<Outlet/>

         </>
    )
}