import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import icon from '../../asset/trash.png';
import '../../css/messagerie.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faUsers,faTrash,faFloppyDisk} from '@fortawesome/free-solid-svg-icons';
import ListGroup from 'react-bootstrap/ListGroup';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

import {
  CDBContainer,
  
} from 'cdbreact';
const DelMessage = (props) => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [items, setItems] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [load , setLoad] = useState(true);

  const server = props.server;
  
  useEffect(() => {
    if (props.mess){
      setItems(props.mess)
      setLoad(false);
    }
  

  }, [props.mess]);




  const handleCheckboxChange = (itemId) => {
    setItems(prevItems => {
      return prevItems.map(item => {
        if (item.id_m === itemId) {
          return { ...item, checked: !item.checked };
        }
        return item;
      });
    });
  };
  
  function launch_toast() {
    var x = document.getElementById("toast")
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", "");}, 2000);
}
  
  
  const handleDeleteChecked = () => {
    const checkedItems = items.filter(item => item.checked);
  
    // Obtenez uniquement les IDs des éléments cochés
    const checkedItemIds = checkedItems.map(item => item.id_m); // Utilisez item.id au lieu de item.id_m
  
    axios.post(`${server}/del`, checkedItemIds)
      .then(response => {

          setMessage('message supprimé');
          launch_toast();
      
   
      })
      .catch(error => {
        console.error('Erreur lors de la suppression des éléments :', error);
      });
  };
    
  const handleDeleteChecked2 = (id_m) => { 
    axios.post(`${server}/del_trash/${id_m}`,)
      .then(response => {
        console.log('suppp');
      })
      .catch(error => {
        console.error('Erreur lors de la suppression des éléments :', error);
      });
  };
  
  return (
    <>
     
     <Card style={{marginTop:'20px'}}>

<Card.Body>
  <Card.Title>
</Card.Title>
  <CDBContainer style={{maxHeight:'65vh'}}> 


  <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
    <div><p style={{fontSize:'20px',fontWeight:'500'}}>Corbeille</p></div>
<div style={{display:'flex'}}>  <Button variant="primary"  className="mb-3">Enregister</Button>{' '}
<Button variant="danger"  className="mb-3 ms-3" onClick={handleDeleteChecked}>Supprimer </Button>{''}</div>


</div>
<div >
<Form>
<Form.Group className="mb-3" controlId="formGroupEmail">
  <Form.Control type="text"  value={filterText} onChange={(e) => setFilterText(e.target.value)} placeholder="Rechercher les messages..." />
</Form.Group>
</Form>

</div>

  <ListGroup as="ul" style={{height:'50vh',overflow:'auto'}}>




{load ? ( <> <div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'90%'}}>
    <svg className="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
     <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
    </div></>) : 
    ( 

    items =='' ? (<><h3 style={{color:'rgba(0,0,0,0.3)' ,display:'flex', alignItems:'center',justifyContent:'center',height:'90%' }}>-  aucun message  -</h3></>) : (
    
    <>         
    
    
     {items.map(item => (

<Link to={`/Inbox/mes/${item.id_m}`} style={{textDecoration:'none' ,zIndex:"0"}} >
<ListGroup.Item
as="li"
className={`d-flex justify-content-between align-items-start mail ${item.lu === 1 ? 'luu' : ''}`}
key={item.id}
style={{listStyle:'none'}}
>
<div 
        style={{ zIndex: '0', cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation(); // Arrête la propagation de l'événement
        
        }}
>
<Form.Check
            inline
            type="checkbox"
            checked={item.checked}
            onChange={() => handleCheckboxChange(item.id_m)}
            style={{ zIndex: '-1' }}
    />

</div>


<div className="ms-3 me-auto">
<div className="fw-bold">{item.LIBETAB}</div>
{item.objet}

</div>
<div className='btnn'>

<Button variant="danger" size="sm" className='ms-3' onClick={(e) => {
    e.stopPropagation(); // Arrête la propagation de l'événement
    e.preventDefault();
    handleDeleteChecked2(item.id_m)
    // Ici, item.id_m doit être l'ID de l'élément que vous souhaitez mettre à jour
  }} >
<FontAwesomeIcon icon={faTrash} />
  </Button>{' '}

</div>
<div>
<Badge bg="danger" pill style={{float:'right'}}>
{item.lu === 1 ? (<></>):(<>.</>)}
</Badge>
<br></br>
<div style={{fontSize:'13px' , color:'rgba(0,0,0,0.5)' ,fontWeight:'500'}}>{item.date}</div>
</div>
</ListGroup.Item>

</Link>
))}</>))   
     }


</ListGroup>
</CDBContainer>

</Card.Body>
</Card>




      <div className="mes">
        <div className="main-t">
          <div className="sel"></div>
          <div className="nom">nom</div>
          <div className="sujet">Sujet</div>
          <div className="groupe">Groupe</div>
          <div className="fichier">Fichier</div>
          <div className="voir">Voir</div>
        </div>
        <div className="scrol">
          {items.map(item => (
            <div className={item.lu === '1' ? 'main-s' : 'main-s'} key={item.id_m}>
              <div className="sel">
                <input
                  type="checkbox"
                  checked={item.checked}
                  onChange={() => handleCheckboxChange(item.id_m)}
                />
              </div>
              <div className="nom">{item.LIBETAB}</div>
              <div className="sujet">{item.objet}</div>
              {item.groupe === '' ? (
                <div className="groupe"></div>
              ) : (
                <div className="groupe">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
              )}
              <div className="fichier"></div>
        
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DelMessage;
